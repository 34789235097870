// src/games/poker/controls.js
import React, { useState } from 'react';
import './controls.module.css';

const Controls = ({ onCheck, onRaise, onFold }) => {
  const [betAmount, setBetAmount] = useState(50); // Default bet amount

  return (
    <div className="controls">
      <input
        type="range"
        min="10"
        max="500"
        step="10"
        value={betAmount}
        onChange={(e) => setBetAmount(Number(e.target.value))}
        className="bet-slider"
      />
      <span className="bet-amount">Bet: ${betAmount}</span>
      <button onClick={() => onRaise(betAmount)}>Raise</button>
      <button onClick={onCheck}>Check</button>
      <button onClick={onFold}>Fold</button>
    </div>
  );
};

export default Controls;
