import React, { createContext, useState, useEffect, useContext } from 'react';
import { useWallet } from '@alephium/web3-react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [walletAddress, setWalletAddress] = useState(null);

  const walletContext = useWallet();
  const connectionStatus = walletContext?.connectionStatus;
  const connected = connectionStatus === 'connected';
  const wallet = walletContext?.account;

  // Ensure the correct API URL is used
  const apiUrl = process.env.REACT_APP_API_URL || 'https://dohcloud.azurewebsites.net'; // Should be your backend API

  // Function to check signup status without requiring a token (handled by backend with Managed Identity)
  const checkSignupStatus = async (walletAddress) => {
    try {
      // Ensure the URL is correctly formed for your backend API
      const response = await fetch(`${apiUrl}/checksignup?walletAddress=${walletAddress}`);
      if (!response.ok) throw new Error('Error checking signup status');
      
      const result = await response.json();
      setIsAuthenticated(true);
      setHasSignedUp(result.hasSignedUp);
      setIsApproved(result.isApproved);
    } catch (error) {
      console.error('Error checking signup status:', error);
      setIsAuthenticated(false);
    } finally {
      setCheckingAuth(false);
    }
  };

  useEffect(() => {
    if (connected && wallet?.address) {
      setWalletAddress(wallet.address);
      checkSignupStatus(wallet.address);
    } else {
      setIsAuthenticated(false);
      setCheckingAuth(false);
    }
  }, [connected, wallet, apiUrl]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isApproved,
        hasSignedUp,
        walletAddress,
        checkingAuth,
      }}
    >
      {!checkingAuth ? children : <p>Checking authentication status...</p>}
    </AuthContext.Provider>
  );
};

// Custom hook to use Auth context
export const useAuth = () => useContext(AuthContext);
