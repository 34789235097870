import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './dohvote.css';

const DOHvote = ({
  token1Address,
  tokenName,
  tokenLogo,
  onVotesUpdate,
  currentVotes,
}) => {
  const [bullishVotes, setBullishVotes] = useState(currentVotes?.bullishVotes || 0);
  const [bearishVotes, setBearishVotes] = useState(currentVotes?.bearishVotes || 0);
  const [userVote, setUserVote] = useState(null);
  const [submittingVote, setSubmittingVote] = useState(false);
  const [userId, setUserId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reason, setReason] = useState('');

  useEffect(() => {
    // Fetch wallet address for user, placeholder used here
    const fetchWalletAddress = async () => {
      const address = '0xYourWalletAddress'; // Replace with wallet connection
      setUserId(address);
    };
    fetchWalletAddress();
  }, []);

  // Update votes whenever currentVotes updates
  useEffect(() => {
    if (currentVotes) {
      setBullishVotes(currentVotes.bullishVotes || 0);
      setBearishVotes(currentVotes.bearishVotes || 0);
    }
  }, [currentVotes]);

  const handleVote = async (voteType) => {
    if (!userId) {
      setErrorMessage('Please connect your wallet.');
      return;
    }

    if (!token1Address) {
      setErrorMessage('Missing token ID.');
      return;
    }

    if (!reason) {
      setErrorMessage('Please provide a reason for your vote.');
      return;
    }

    setSubmittingVote(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://dohprod.azurewebsites.net/communitysentiment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, tokenId: token1Address, voteType, reason }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error submitting vote: ${errorText}`);
      }

      // Update local vote count and user vote
      if (voteType === 'bullish') {
        setBullishVotes((prev) => prev + 1);
      } else {
        setBearishVotes((prev) => prev + 1);
      }
      setUserVote(voteType);

      // Call onVotesUpdate to update votes in parent
      onVotesUpdate(
        token1Address,
        voteType === 'bullish' ? bullishVotes + 1 : bullishVotes,
        voteType === 'bearish' ? bearishVotes + 1 : bearishVotes
      );
    } catch (error) {
      setErrorMessage('Error submitting vote. Please try again.');
      console.error(error);
    } finally {
      setSubmittingVote(false);
    }
  };

  return (
    <div className="sentiment-container">
      <div className="sentiment-header">
        {tokenLogo && (
          <img
            src={tokenLogo}
            alt={tokenName}
            className="sentiment-token-logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/50?text=No+Logo';
            }}
          />
        )}
        <h2 className="sentiment-token-name">{tokenName}</h2>
      </div>

      <p className="sentiment-total-votes">{bullishVotes + bearishVotes} votes</p>

      <div className="vote-bar">
        {bullishVotes + bearishVotes > 0 ? (
          <>
            <div
              className="bullish-bar"
              style={{ width: `${(bullishVotes / (bullishVotes + bearishVotes)) * 100}%`, backgroundColor: 'green' }}
              title={`${bullishVotes} Bullish Votes`}
            >
              <span className="vote-percentage">
                {(bullishVotes / (bullishVotes + bearishVotes) * 100).toFixed(0)}%
              </span>
            </div>
            <div
              className="bearish-bar"
              style={{ width: `${(bearishVotes / (bullishVotes + bearishVotes)) * 100}%`, backgroundColor: 'red' }}
              title={`${bearishVotes} Bearish Votes`}
            >
              <span className="vote-percentage">
                {(bearishVotes / (bullishVotes + bearishVotes) * 100).toFixed(0)}%
              </span>
            </div>
          </>
        ) : (
          <div className="no-votes">No votes yet</div>
        )}
      </div>

      <div className="reason-input">
        <input
          id="reason"
          type="text"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Provide your reason here"
          className="reason-text-input"
        />
      </div>

      <div className="vote-buttons">
        <button
          onClick={() => handleVote('bullish')}
          disabled={userVote === 'bullish' || submittingVote}
          className={`vote-button bullish ${userVote === 'bullish' ? 'voted' : ''}`}
        >
          {submittingVote && userVote === 'bullish' ? 'Submitting...' : 'Bullish'}
        </button>
        <button
          onClick={() => handleVote('bearish')}
          disabled={userVote === 'bearish' || submittingVote}
          className={`vote-button bearish ${userVote === 'bearish' ? 'voted' : ''}`}
        >
          {submittingVote && userVote === 'bearish' ? 'Submitting...' : 'Bearish'}
        </button>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

DOHvote.propTypes = {
  token1Address: PropTypes.string.isRequired,
  tokenName: PropTypes.string.isRequired,
  tokenLogo: PropTypes.string,
  onVotesUpdate: PropTypes.func.isRequired,
  currentVotes: PropTypes.shape({
    bullishVotes: PropTypes.number,
    bearishVotes: PropTypes.number,
  }),
};

export default DOHvote;