import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Marquee from 'react-marquee-slider';
import './tokenticker.css';

const tokenListUrl = 'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json';
const apiUrl = 'https://api.mobula.io/api/1/market/blockchain/pairs?blockchain=Alephium';
const apiKey = process.env.REACT_APP_MOBULA_API_KEY;

const fetchTokenList = async () => {
  try {
    const response = await axios.get(tokenListUrl);
    return response.data.tokens || [];
  } catch (error) {
    console.error('Error fetching token list:', error);
    return [];
  }
};

const fetchTopTokens = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: apiKey },
    });
    const data = response.data.data;

    return data
      .filter(entry => entry.pair.volume24h > 0 && entry.pair.liquidity >= 500)
      .sort((a, b) => b.pair.volume24h - a.pair.volume24h)
      .slice(0, 20)
      .map(entry => ({
        symbol: entry.pair.token1.symbol,
        price: entry.price || 0,
        volume: entry.pair.volume24h || 0,
        priceChange24h: entry.price_change_24h || 0,
        logo: '',
      }));
  } catch (error) {
    console.error('Failed to fetch top tokens:', error);
    return [];
  }
};

const getLogoURI = (symbol, tokenList) => {
  if (symbol === 'BTC') return 'https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=010';
  if (symbol === 'ETH') return 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=010';
  if (symbol === 'ANS') return 'https://raw.githubusercontent.com/alephium/token-list/master/logos/ANSd.png';

  const token = tokenList.find(token => token.symbol === symbol);
  return token ? token.logoURI : 'https://via.placeholder.com/150?text=No+Logo';
};

const formatPrice = (price) => {
  const priceString = price.toFixed(8);
  const [whole, decimals] = priceString.split('.');
  const leadingZerosCount = decimals.match(/^0+/)?.[0]?.length || 0;

  if (leadingZerosCount > 3) {
    const remainingDecimals = decimals.slice(leadingZerosCount);
    return `0.0<sup>${leadingZerosCount}</sup>${remainingDecimals}`;
  } else {
    return `$${price.toFixed(4)}`;
  }
};

const TokenTicker = () => {
  const [tokens, setTokens] = useState([]);
  const [velocity, setVelocity] = useState(25);

  useEffect(() => {
    const updateVelocity = () => {
      const screenWidth = window.innerWidth;
      setVelocity(screenWidth < 480 ? 10 : screenWidth < 768 ? 15 : 25);
    };

    updateVelocity();
    window.addEventListener('resize', updateVelocity);
    return () => window.removeEventListener('resize', updateVelocity);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tokenList, topTokens] = await Promise.all([fetchTokenList(), fetchTopTokens()]);
        
        const tokensWithLogos = topTokens.map(token => ({
          ...token,
          logo: getLogoURI(token.symbol, tokenList),
        }));

        setTokens(tokensWithLogos);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="token-ticker-container">
      <Marquee velocity={velocity} resetAfterTries={200} scatterRandomly={false}>
        {tokens.map((token, index) => (
          <div key={index} className="ticker-item">
            {token.logo && <img src={token.logo} alt={token.symbol} className="token-logo" />}
            <span className="token-symbol">{token.symbol}</span>
            <span
              className="token-price"
              dangerouslySetInnerHTML={{ __html: formatPrice(token.price) }}
            ></span>
            <span
              className={`token-change ${token.priceChange24h >= 0 ? 'positive-change' : 'negative-change'}`}
            >
              {token.priceChange24h.toFixed(2)}%
            </span>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default TokenTicker;
