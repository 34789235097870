import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useWallet } from '@alephium/web3-react';
import { useAuth } from '../auth'; // Import the AuthProvider
import NodeStats from './nodestats'; // Import the NodeStats component
import NodeDetails from './nodedetails'; // Import the NodeDetails component
import { useNavigate } from 'react-router-dom'; // Use navigate to redirect to signup
import alphLogo from '../assets/logos/alphbanner.png'; // Import Alephium logo
import './nodemanager.css'; // Include any necessary styling.

const apiUrl = process.env.REACT_APP_API_URL || 'https://dohcloud.azurewebsites.net';

const NodeManager = () => {
  const [prodNodes, setProdNodes] = useState([]);
  const [testNodes, setTestNodes] = useState([]);
  const [error, setError] = useState(null);
  const [deploying, setDeploying] = useState(false);
  const [logs, setLogs] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedNode, setExpandedNode] = useState(null);
  const [webSocket, setWebSocket] = useState(null);
  const [syncedStatus, setSyncedStatus] = useState({});

  const walletContext = useWallet();
  const connectionStatus = walletContext?.connectionStatus;
  const wallet = walletContext?.account;
  const connected = connectionStatus === 'connected';
  const walletAddress = wallet?.address;

  const { isApproved, hasSignedUp, checkingAuth } = useAuth();
  const navigate = useNavigate();

  const appendLog = (message) => {
    setLogs((prevLogs) => [message, ...prevLogs]);
  };

  const fetchNodeSyncedStatus = async (nodeIp, nodeName) => {
    if (!nodeIp || !nodeName) {
      console.error('Node IP or Node Name is missing!');
      return null;
    }

    try {
      const response = await axios.get(`${apiUrl}/proxy`, {
        params: { nodeIp, nodeName },
      });
      const { synced } = response.data;
      return synced;
    } catch (err) {
      console.error('Failed to fetch synced status:', err.message);
      return null;
    }
  };

  useEffect(() => {
    if (connected && walletAddress && isApproved && hasSignedUp) {
      fetchUserNodes(walletAddress);
      const interval = setInterval(() => {
        fetchUserNodes(walletAddress);
      }, 300000); // Refresh every 5 minutes.
      return () => clearInterval(interval);
    }
  }, [connected, walletAddress, isApproved, hasSignedUp]);

  const fetchUserNodes = async (walletAddress) => {
    try {
      const response = await axios.get(`${apiUrl}/getusernodes`, {
        params: { walletAddress },
      });
      const nodes = response.data;
      setProdNodes(nodes.filter((node) => node.type === 'prod'));
      setTestNodes(nodes.filter((node) => node.type === 'test'));

      if (nodes.length > 0) {
        setExpandedNode(nodes[0]); // Automatically expands the first node
        fetchNodeMetrics(nodes[0].nodeName);
        const status = await fetchNodeSyncedStatus(nodes[0].publicIP, nodes[0].nodeName);
        setSyncedStatus((prevStatus) => ({
          ...prevStatus,
          [nodes[0].nodeName]: status,
        }));
      }

      setError(null);
    } catch (err) {
      console.error('Error fetching user nodes:', err);
      setError('Failed to fetch user nodes. Please try reconnecting your wallet.');
    }
  };

  const fetchNodeMetrics = async (nodeName) => {
    try {
      const response = await axios.get(`${apiUrl}/getnodemetrics`, {
        params: { nodeName },
      });
      setMetrics(response.data);
      appendLog(`Metrics for ${nodeName} fetched successfully`);
    } catch (err) {
      appendLog(`Failed to fetch metrics for ${nodeName}: ${err.message}`);
      setMetrics({});
    }
  };

  const handleNodeClick = async (node) => {
    if (expandedNode?.id === node.id) {
      setExpandedNode(null); // Collapse if clicked again
    } else {
      setExpandedNode(node); // Expand node details
      setLogs([]); // Clear logs when switching nodes.

      try {
        const status = await fetchNodeSyncedStatus(node.publicIP, node.nodeName);
        setSyncedStatus((prevStatus) => ({
          ...prevStatus,
          [node.nodeName]: status,
        }));

        if (webSocket) {
          webSocket.close();
          setWebSocket(null); // Clear the WebSocket state after closing
        }

        const wsResponse = await axios.get(`${apiUrl}/generateWebSocketUrl`, {
          params: { nodeName: node.nodeName },
        });
        const webSocketUrl = wsResponse.data;

        const ws = new WebSocket(webSocketUrl);
        ws.onopen = () => {
          console.log('WebSocket connection opened');
        };

        ws.onmessage = (message) => appendLog(message.data);

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
          ws.close();
        };

        ws.onclose = (event) => {
          console.log('WebSocket closed:', event.reason);
        };

        setWebSocket(ws);
        fetchNodeMetrics(node.nodeName);
      } catch (err) {
        setError('Failed to fetch node details or connect to logs');
        console.error(err.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.close();
        setWebSocket(null);
      }
    };
  }, [webSocket]);

  const deployNode = async (isTestNode = false) => {
    if (!connected || !walletAddress) {
      setError('Please connect your wallet first.');
      return;
    }

    setDeploying(true);
    try {
      const nodeName = prompt('Enter the DNS name for the node:');
      if (!nodeName) {
        alert('Node name is required!');
        setDeploying(false);
        return;
      }

      // Update the image repository based on the type of node (mainnet or testnet)
      const imageRepo = isTestNode ? 'dohmoney/testnet:latest' : 'dohmoney/mainnet:latest';
      appendLog(`Deploying node: ${nodeName}, Wallet: ${walletAddress}, ImageRepo: ${imageRepo}`);

      const response = await axios.post(`${apiUrl}/deploynode`, { nodeName, walletAddress, imageRepo }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data && response.data.logs) {
        setLogs((prevLogs) => [...prevLogs, ...response.data.logs]);
        localStorage.setItem('globalLogs', JSON.stringify(logs)); // Save logs to localStorage
      }

      const wsResponse = await axios.get(`${apiUrl}/generateWebSocketUrl?nodeName=${nodeName}`);
      const webSocketUrl = wsResponse.data;

      if (webSocket) {
        webSocket.close();
        setWebSocket(null);
      }

      const ws = new WebSocket(webSocketUrl);
      setWebSocket(ws);
      ws.onmessage = (message) => appendLog(message.data);
      ws.onerror = (error) => appendLog('WebSocket error. Please check the connection.');
      ws.onclose = () => appendLog('WebSocket connection closed');

      fetchUserNodes(walletAddress);
    } catch (err) {
      appendLog(`Failed to deploy node: ${err.message}`);
    } finally {
      setDeploying(false);
    }
  };

  const deleteNode = async (nodeName) => {
    try {
      if (window.confirm(`Are you sure you want to delete node "${nodeName}"?`)) {
        appendLog(`Deleting node: ${nodeName}`);
        await axios.delete(`${apiUrl}/deletenode`, { params: { nodeName } });
        fetchUserNodes(walletAddress); // Refresh nodes after deletion
      }
    } catch (err) {
      appendLog(`Failed to delete node: ${err.message}`);
    }
  };

  const filteredNodes = prodNodes.concat(testNodes).filter((node) =>
    node.nodeName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!connected) {
    return <p>Please connect your wallet to check node approval status.</p>;
  }

  if (checkingAuth) {
    return <p>Checking signup and approval status...</p>;
  }

  if (!hasSignedUp) {
    return (
      <div>
        <p>You haven't signed up yet for the beta. Please <button onClick={() => navigate('/home')}>Sign up here</button>.</p>
      </div>
    );
  }

  return (
    <div className="node-manager-container">
      <div className="title-container">
        <h1 className="page-title">Decentralized. Open. Hub.</h1>
        <img src={alphLogo} alt="Alephium" className="alph-logo" />
      </div>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search nodes..."
          className="search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="node-stats-container">
        <NodeStats prodNodes={prodNodes} testNodes={testNodes} />
      </div>

      <div className="button-group">
        <button className="deploy-button" onClick={() => deployNode(false)} disabled={deploying}>
          {deploying ? 'Deploying Node...' : 'Deploy Node'}
        </button>
        <div className="dropdown-content">
          <button onClick={() => deployNode(false)}>Mainnet</button>
          <button onClick={() => deployNode(true)}>Testnet</button>
        </div>
      </div>

      <table className="node-table">
        <thead>
          <tr>
            <th>Node Name</th>
            <th>IP</th>
            <th>Status</th>
            <th>Version</th>
            <th>CPU</th>
            <th>Memory</th>
            <th>Network Traffic</th>
            <th>Actions</th> {/* Added actions column */}
          </tr>
        </thead>
        <tbody>
          {filteredNodes.map((node) => (
            <React.Fragment key={node.id}>
              <tr onClick={() => handleNodeClick(node)} className={expandedNode?.id === node.id ? 'expanded' : ''}>
                <td>{node.nodeName}</td>
                <td>{node.publicIP || 'Pending'}</td>
                <td>{syncedStatus[node.nodeName] === null ? 'Loading...' : (syncedStatus[node.nodeName] ? 'Synced' : 'Not Synced')}</td>
                <td>{metrics.version || 'N/A'}</td>
                <td>{metrics.cpuUsage || 'N/A'}</td>
                <td>{metrics.memoryUsage || 'N/A'}</td>
                <td>{metrics.networkTraffic || 'N/A'}</td>
                <td>
                  <button onClick={() => deleteNode(node.nodeName)}>Delete</button> {/* Add Delete Button */}
                </td>
              </tr>
              {expandedNode?.id === node.id && (
                <tr>
                  <td colSpan="8">
                    <NodeDetails
                      selectedNode={node}
                      metrics={metrics}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div className="bottom-logs-container">
        <div className="logs-section">
          <h3>Global Logs</h3>
          <pre className="logs-output">{logs.join('\n')}</pre>
        </div>

        <div className="console-logs-section">
          <h3>Console Logs</h3>
          <pre className="console-logs-output">Console output for the selected node will appear here.</pre>
        </div>
      </div>
    </div>
  );
};

export default NodeManager;
