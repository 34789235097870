import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestBalances = () => {
  const [address, setAddress] = useState('');
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch wallet info
  const fetchWalletInfo = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://dohprod.azurewebsites.net/api_GetWalletInfo', {
        address,
      });
      setBalances(response.data.balances);
    } catch (err) {
      setError('Error fetching data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Wallet Balance Viewer</h2>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Enter Address:
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', width: '300px' }}
          />
        </label>
        <button onClick={fetchWalletInfo} style={{ marginLeft: '10px', padding: '5px 10px' }}>
          Fetch Balances
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {balances.length > 0 && (
        <div>
          <h3>Token Balances</h3>
          {balances.map((balance, index) =>
            balance.token ? (
              <div key={index} style={{ marginBottom: '15px' }}>
                <p>
                  <strong>Token Name:</strong> {balance.token.name} ({balance.token.symbol})
                </p>
                <p>
                  <strong>Balance:</strong> {balance.balance}
                </p>
                <p>
                  <strong>Description:</strong> {balance.token.description}
                </p>
                {balance.token.logo && (
                  <img src={balance.token.logo} alt={balance.token.name} style={{ width: '50px' }} />
                )}
              </div>
            ) : null
          )}
          <h3>NFTs</h3>
          {balances.map((balance, index) =>
            balance.nft ? (
              <div key={index} style={{ marginBottom: '15px' }}>
                <p>
                  <strong>NFT Name:</strong> {balance.nft.name}
                </p>
                <p>
                  <strong>Description:</strong> {balance.nft.description || 'No description'}
                </p>
                <p>
                  <strong>Collection:</strong> {balance.nft.collection?.name || 'N/A'}
                </p>
                {balance.nft.image && (
                  <img src={balance.nft.image} alt={balance.nft.name} style={{ width: '100px' }} />
                )}
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default TestBalances;
