import React, { useState } from 'react';

const DOHnut = () => {
  // State variables
  const [numNFTs, setNumNFTs] = useState(1);
  const [rarity, setRarity] = useState('Pink Common');
  const [lpValue, setLpValue] = useState(780685.71); // Example default
  const [votePercentage, setVotePercentage] = useState(50); // Example default
  const [exPerEpoch, setExPerEpoch] = useState(100); // Example default EX per epoch

  // Reward rates
  const rewardRates = {
    'Pink Common': 0.7,
    'Blue Rare': 1.0,
    'Green Epic': 1.5,
    'Yellow Legendary': 2.5,
  };

  // Calculate estimated rewards
  const calculateRewards = () => {
    const baseReward = exPerEpoch * (votePercentage / 100);
    const rewardRate = rewardRates[rarity];
    return (numNFTs * baseReward * rewardRate).toFixed(2);
  };

  // Calculate voting power
  const calculateVotingPower = () => {
    return (lpValue * (votePercentage / 100)).toFixed(2);
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>DOHnut Reward and Voting Calculator</h2>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Number of NFTs Held:
          <input
            type="number"
            value={numNFTs}
            onChange={(e) => setNumNFTs(Number(e.target.value))}
            min="1"
            style={{ marginLeft: '10px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Select Rarity:
          <select
            value={rarity}
            onChange={(e) => setRarity(e.target.value)}
            style={{ marginLeft: '10px' }}
          >
            <option value="Pink Common">Pink Common</option>
            <option value="Blue Rare">Blue Rare</option>
            <option value="Green Epic">Green Epic</option>
            <option value="Yellow Legendary">Yellow Legendary</option>
          </select>
        </label>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>
          LP Value (ALPH):
          <input
            type="number"
            value={lpValue}
            onChange={(e) => setLpValue(Number(e.target.value))}
            style={{ marginLeft: '10px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Vote Percentage (%):
          <input
            type="number"
            value={votePercentage}
            onChange={(e) => setVotePercentage(Number(e.target.value))}
            min="0"
            max="100"
            style={{ marginLeft: '10px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>
          EX Generated per Epoch:
          <input
            type="number"
            value={exPerEpoch}
            onChange={(e) => setExPerEpoch(Number(e.target.value))}
            style={{ marginLeft: '10px' }}
          />
        </label>
      </div>
      <hr />
      <h3>Estimated Rewards and Voting Power</h3>
      <p>Estimated Rewards (EX): {calculateRewards()}</p>
      <p>Estimated Voting Power (ALPH): {calculateVotingPower()}</p>
    </div>
  );
};

export default DOHnut;
