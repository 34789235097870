import React, { useEffect, useState } from 'react';
import axios from 'axios';

function TokenListComponent() {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedAddress, setCopiedAddress] = useState(null);

  const apiUrl = 'https://dohcloud.azurewebsites.net/api_token_transactions';

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPools(response.data);
      } catch (err) {
        setError('Failed to fetch token data.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPools();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedAddress(text);
      setTimeout(() => setCopiedAddress(null), 2000); // Reset the copied state after 2 seconds
    });
  };

  const formatAddress = (address) => {
    if (!address) return 'N/A';
    return `${address.slice(0, 6)}...${address.slice(-4)}`; // Shortens the address display
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Token Pools List</h1>
      {pools.length === 0 ? (
        <p>No data available.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Token 1 Symbol</th>
              <th>Token 1 Address</th>
              <th>Token 1 Onchain Price</th>
              <th>Token 0 Symbol</th>
              <th>Token 0 Address</th>
              <th>Token 0 Onchain Price</th>
              <th>Volume</th>
              <th>TVL</th>
              <th>Pool Address</th> {/* Added a new column for Pool Address */}
            </tr>
          </thead>
          <tbody>
            {pools.map((pool, index) => (
              <tr key={index}>
                <td>{pool.token1.symbol}</td>
                <td>
                  <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => copyToClipboard(pool.token1.address)}
                    title="Click to copy address"
                  >
                    {formatAddress(pool.token1.address)}
                  </span>
                  {copiedAddress === pool.token1.address && <span style={{ marginLeft: '8px', color: 'green' }}>Copied!</span>}
                </td>
                <td>{pool.token1.onchainPrice ? `$${Number(pool.token1.onchainPrice).toFixed(4)}` : 'N/A'}</td>
                <td>{pool.token0.symbol}</td>
                <td>
                  <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => copyToClipboard(pool.token0.address)}
                    title="Click to copy address"
                  >
                    {formatAddress(pool.token0.address)}
                  </span>
                  {copiedAddress === pool.token0.address && <span style={{ marginLeft: '8px', color: 'green' }}>Copied!</span>}
                </td>
                <td>{pool.token0.onchainPrice ? `$${Number(pool.token0.onchainPrice).toFixed(4)}` : 'N/A'}</td>
                <td>{Number(pool.volume).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>{Number(pool.tvl).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>
                  <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => copyToClipboard(pool.address)}
                    title="Click to copy address"
                  >
                    {formatAddress(pool.address)}
                  </span>
                  {copiedAddress === pool.address && <span style={{ marginLeft: '8px', color: 'green' }}>Copied!</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TokenListComponent;
