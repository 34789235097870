"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionBuilder = void 0;
const utils_1 = require("../utils");
const api_1 = require("../api");
const address_1 = require("../address");
const signer_1 = require("./signer");
const codec_1 = require("../codec");
const transaction_1 = require("../transaction");
const hash_1 = require("../codec/hash");
class TransactionBuilder {
    static from(param0, param1, customFetch) {
        const nodeProvider = typeof param0 === 'string' ? new api_1.NodeProvider(param0, param1, customFetch) : param0;
        return new (class extends TransactionBuilder {
            get nodeProvider() {
                return nodeProvider;
            }
        })();
    }
    static validatePublicKey(params, publicKey, keyType) {
        const address = (0, address_1.addressFromPublicKey)(publicKey, keyType);
        if (address !== params.signerAddress) {
            throw new Error('Unmatched public key');
        }
    }
    async buildTransferTx(params, publicKey) {
        const data = this.buildTransferTxParams(params, publicKey);
        const response = await this.nodeProvider.transactions.postTransactionsBuild(data);
        return this.convertTransferTxResult(response);
    }
    async buildDeployContractTx(params, publicKey) {
        const data = this.buildDeployContractTxParams(params, publicKey);
        const response = await this.nodeProvider.contracts.postContractsUnsignedTxDeployContract(data);
        return this.convertDeployContractTxResult(response);
    }
    async buildExecuteScriptTx(params, publicKey) {
        const data = this.buildExecuteScriptTxParams(params, publicKey);
        const response = await this.nodeProvider.contracts.postContractsUnsignedTxExecuteScript(data);
        return this.convertExecuteScriptTxResult(response);
    }
    async buildChainedTx(params, publicKeys) {
        if (params.length !== publicKeys.length) {
            throw new Error('The number of build chained transaction parameters must match the number of public keys provided');
        }
        const data = params.map((param, index) => {
            const paramType = param.type;
            switch (paramType) {
                case 'Transfer': {
                    const value = this.buildTransferTxParams(param, publicKeys[index]);
                    return { type: paramType, value };
                }
                case 'DeployContract': {
                    const value = this.buildDeployContractTxParams(param, publicKeys[index]);
                    return { type: paramType, value };
                }
                case 'ExecuteScript': {
                    const value = this.buildExecuteScriptTxParams(param, publicKeys[index]);
                    return { type: paramType, value };
                }
                default:
                    throw new Error(`Unsupported transaction type: ${paramType}`);
            }
        });
        const buildChainedTxsResponse = await this.nodeProvider.transactions.postTransactionsBuildChained(data);
        const results = buildChainedTxsResponse.map((buildResult) => {
            const buildResultType = buildResult.type;
            switch (buildResultType) {
                case 'Transfer': {
                    const buildTransferTxResult = buildResult.value;
                    return {
                        ...this.convertTransferTxResult(buildTransferTxResult),
                        type: buildResultType
                    };
                }
                case 'DeployContract': {
                    const buildDeployContractTxResult = buildResult.value;
                    return {
                        ...this.convertDeployContractTxResult(buildDeployContractTxResult),
                        type: buildResultType
                    };
                }
                case 'ExecuteScript': {
                    const buildExecuteScriptTxResult = buildResult.value;
                    return {
                        ...this.convertExecuteScriptTxResult(buildExecuteScriptTxResult),
                        type: buildResultType
                    };
                }
                default:
                    throw new Error(`Unexpected transaction type: ${buildResultType} for ${buildResult.value.txId}`);
            }
        });
        return results;
    }
    static buildUnsignedTx(params) {
        const unsignedTxBin = (0, utils_1.hexToBinUnsafe)(params.unsignedTx);
        const decoded = codec_1.unsignedTxCodec.decode(unsignedTxBin);
        const txId = (0, utils_1.binToHex)((0, hash_1.blakeHash)(unsignedTxBin));
        const [fromGroup, toGroup] = (0, transaction_1.groupIndexOfTransaction)(decoded);
        return {
            fromGroup: fromGroup,
            toGroup: toGroup,
            unsignedTx: params.unsignedTx,
            txId: txId,
            gasAmount: decoded.gasAmount,
            gasPrice: decoded.gasPrice
        };
    }
    buildTransferTxParams(params, publicKey) {
        TransactionBuilder.validatePublicKey(params, publicKey, params.signerKeyType);
        const { destinations, gasPrice, ...rest } = params;
        return {
            fromPublicKey: publicKey,
            fromPublicKeyType: params.signerKeyType,
            destinations: (0, signer_1.toApiDestinations)(destinations),
            gasPrice: (0, api_1.toApiNumber256Optional)(gasPrice),
            ...rest
        };
    }
    buildDeployContractTxParams(params, publicKey) {
        TransactionBuilder.validatePublicKey(params, publicKey, params.signerKeyType);
        const { initialAttoAlphAmount, initialTokenAmounts, issueTokenAmount, gasPrice, ...rest } = params;
        return {
            fromPublicKey: publicKey,
            fromPublicKeyType: params.signerKeyType,
            initialAttoAlphAmount: (0, api_1.toApiNumber256Optional)(initialAttoAlphAmount),
            initialTokenAmounts: (0, api_1.toApiTokens)(initialTokenAmounts),
            issueTokenAmount: (0, api_1.toApiNumber256Optional)(issueTokenAmount),
            gasPrice: (0, api_1.toApiNumber256Optional)(gasPrice),
            ...rest
        };
    }
    buildExecuteScriptTxParams(params, publicKey) {
        TransactionBuilder.validatePublicKey(params, publicKey, params.signerKeyType);
        const { attoAlphAmount, tokens, gasPrice, ...rest } = params;
        return {
            fromPublicKey: publicKey,
            fromPublicKeyType: params.signerKeyType,
            attoAlphAmount: (0, api_1.toApiNumber256Optional)(attoAlphAmount),
            tokens: (0, api_1.toApiTokens)(tokens),
            gasPrice: (0, api_1.toApiNumber256Optional)(gasPrice),
            ...rest
        };
    }
    convertTransferTxResult(result) {
        return {
            ...result,
            gasPrice: (0, api_1.fromApiNumber256)(result.gasPrice)
        };
    }
    convertDeployContractTxResult(result) {
        const contractId = (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(result.contractAddress));
        return {
            ...result,
            groupIndex: result.fromGroup,
            contractId,
            gasPrice: (0, api_1.fromApiNumber256)(result.gasPrice)
        };
    }
    convertExecuteScriptTxResult(result) {
        return {
            ...result,
            groupIndex: result.fromGroup,
            gasPrice: (0, api_1.fromApiNumber256)(result.gasPrice)
        };
    }
}
exports.TransactionBuilder = TransactionBuilder;
