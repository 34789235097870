// src/components/tokens/tokendetails.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { createChart, ColorType } from 'lightweight-charts';
import './tokens.css'; // Ensure you have appropriate styles
import alphLogo from '../assets/logos/ALPH.png';
import alphProLogo from '../assets/logos/alphpro.svg';
import TopHoldersTable from './topholders'; // Ensure this path is correct
import Wallet from './wallet';
import { FaShareAlt, FaGlobe, FaTwitter, FaDiscord } from 'react-icons/fa';
import DOHvote from './dohvote'; // Import DOHvote component
import { ANS } from '@alph-name-service/ans-sdk';
import PropTypes from 'prop-types';
import ALPHCalculator from './alphcalc'; // Corrected import path
import './tokens.mobile.css';

const TokenDetails = ({
  selectedPairAddress,
  token1Address,
  token0,
  walletAddress,
  votes,
  tokenList,
  tokenPrices,
  onVotesUpdate,
}) => {
  console.log('TokenDetails Component Rendered with votes:', votes); // Debugging log

  const chartContainerRef = useRef();
  const chartRef = useRef(null);
  const seriesRef = useRef(null); // Ref for the series

  // Separate loading states
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingTrades, setLoadingTrades] = useState(false);
  const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
  const [loadingVotes, setLoadingVotes] = useState(false); // Currently not used

  const [historicalData, setHistoricalData] = useState([]);
  const [tradeData, setTradeData] = useState([]);
  const [error, setError] = useState(null);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [tokenDecimals, setTokenDecimals] = useState(0);
  const [holderError, setHolderError] = useState(null);
  const [showHolders, setShowHolders] = useState(true);
  const [screenshotURL, setScreenshotURL] = useState(null);
  const [fetchAllTradeData, setFetchAllTradeData] = useState(false);
  const [showDOHvotePopup, setShowDOHvotePopup] = useState(false); // State for DOHvote popup

  // ANS states
  const [ansName, setAnsName] = useState('');
  const [ansUri, setAnsUri] = useState('');

  // State to hold the selected address
  const [selectedAddress, setSelectedAddress] = useState(walletAddress || '');

  const route = '28069487-b064-4962-8566-ae1eae0795f3'; // Ensure this is kept secure

  // State to manage ALPHCalculator visibility
  const [showALPHCalculator, setShowALPHCalculator] = useState(false);

  // Toggle DOHvote Popup
  const toggleDOHvotePopup = () => {
    setShowDOHvotePopup((prev) => {
      const newState = !prev;
      console.log('showDOHvotePopup toggled to:', newState);
      return newState;
    });
  };

  // Toggle Holders Visibility
  const toggleHoldersVisibility = () => {
    setShowHolders((prevShowHolders) => {
      const newShowHolders = !prevShowHolders;
      console.log('showHolders toggled to:', newShowHolders);
      return newShowHolders;
    });
  };

  // Debounce holder selection to prevent rapid state changes
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Fetch ANS Profile
  const fetchAnsProfile = useCallback(async (address) => {
    try {
      const ans = new ANS('mainnet');
      const profile = await ans.getProfile(address);
      console.log('Fetched ANS profile:', profile); // Debugging log
      setAnsName(profile?.name || '');
      setAnsUri(profile?.imgUri || '');
    } catch (error) {
      console.error('Error fetching ANS profile:', error);
      setAnsName('');
      setAnsUri('');
    }
  }, []);

  // Handle selection of a holder from the top holders table
  const handleHolderSelect = useCallback(
    debounce((address) => {
      setSelectedAddress(address);
      fetchAnsProfile(address); // Now fetchAnsProfile is defined
    }, 300), // 300ms debounce delay
    [fetchAnsProfile]
  );

  // Utility functions defined outside the component to avoid redefinition on every render

  const formatPrice = (price) => {
    if (price > 99999) {
      return 'N/A';
    }

    const priceString = price.toFixed(8);
    const splitPrice = priceString.split('.');
    let leadingZerosCount = 0;
    const decimals = splitPrice[1];

    for (let i = 0; decimals && i < decimals.length; i++) {
      if (decimals[i] === '0') {
        leadingZerosCount++;
      } else {
        break;
      }
    }

    if (leadingZerosCount > 2) {
      const remainingDecimals = decimals.slice(leadingZerosCount);
      return (
        <>
          0.0<sup style={{ fontSize: '0.6em', verticalAlign: 'super' }}>{leadingZerosCount}</sup>
          {remainingDecimals}
        </>
      );
    } else if (price >= 1000 && price < 100000) {
      return `$${price.toFixed(2)}`;
    } else if (price >= 1 && price < 1000) {
      return `$${price.toFixed(2)}`;
    } else if (price < 10) {
      return `$${price.toFixed(4)}`;
    } else if (price >= 100000) {
      return `$${(price / 1000).toFixed(2)}k`;
    } else {
      return `$${price.toFixed(4)}`;
    }
  };

  const formatTokenAmount = (amount) => {
    if (typeof amount !== 'number' || isNaN(amount)) {
      return '0';
    }
    return amount >= 1 ? amount.toFixed(0) : amount.toFixed(1);
  };

  const formatTimeDifference = (tradeDate) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(tradeDate)) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} s`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} d`;

    return `${Math.floor(diffInSeconds / 604800)} w`;
  };

  const formatWalletAddress = (address) => {
    if (!address || typeof address !== 'string' || address.length < 8) {
      return '';
    }
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  // Fetch historical data for 15 days
  const fetchHistoricalData15Days = useCallback(async (pairAddress) => {
    try {
      setLoadingChart(true);
      const options = { method: 'GET', headers: { Authorization: route } };
      const response = await fetch(
        `https://api.mobula.io/api/1/market/history/pair?address=${pairAddress}&amount=1000&blockchain=alephium&usd=true&period=15d`,
        options
      );
      const data = await response.json();
      console.log('Fetched historical data:', data); // Debugging log

      if (!data.data || !Array.isArray(data.data)) {
        throw new Error('Invalid historical data format.');
      }

      const formattedData = data.data.map((item) => ({
        time: item.time / 1000, // Convert milliseconds to seconds
        open: item.open,
        high: item.high,
        low: item.low,
        close: item.close,
        volume: item.volume,
      }));
      console.log('Formatted historical data:', formattedData); // Debugging log
      setHistoricalData(formattedData);
      setError(null);
    } catch (error) {
      console.error('Error fetching 15-day historical data:', error);
      setError('Failed to fetch 15-day historical data.');
      setHistoricalData([]);
    } finally {
      setLoadingChart(false);
    }
  }, [route]);

  // Fetch all historical price data
  const fetchAllHistoricalData = useCallback(async (pairAddress) => {
    try {
      setLoadingChart(true);
      const options = { method: 'GET', headers: { Authorization: route } };
      const response = await fetch(
        `https://api.mobula.io/api/1/market/history/pair?address=${pairAddress}&amount=4000&blockchain=alephium&usd=true&period=all`,
        options
      );
      const data = await response.json();
      console.log('Fetched all historical data:', data); // Debugging log

      if (!data.data || !Array.isArray(data.data)) {
        throw new Error('Invalid historical data format.');
      }

      const formattedData = data.data.map((item) => ({
        time: item.time / 1000,
        open: item.open,
        high: item.high,
        low: item.low,
        close: item.close,
        volume: item.volume,
      }));
      console.log('Formatted all historical data:', formattedData); // Debugging log
      setHistoricalData(formattedData);
      setError(null);
    } catch (error) {
      console.error('Error fetching all historical data:', error);
      setError('Failed to fetch all historical data.');
      setHistoricalData([]);
    } finally {
      setLoadingChart(false);
    }
  }, [route]);

  // Fetch trade data
  const fetchTradeData = useCallback(async (pairAddress) => {
    try {
      setLoadingTrades(true);
      const response = await axios.get(
        `https://api.mobula.io/api/1/market/trades/pair?address=${pairAddress}`,
        {
          headers: { Authorization: route },
        }
      );
      console.log('Fetched trade data:', response.data); // Debugging log
      setTradeData(response.data.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching trade data:', error);
      setError('Failed to fetch trade data.');
      setTradeData([]);
    } finally {
      setLoadingTrades(false);
    }
  }, [route]);

  // Fetch token info
  const fetchTokenInfo = useCallback(async () => {
    if (!token1Address) return;
    try {
      setLoadingTokenInfo(true);
      const response = await axios.get(`https://dohcloud.azurewebsites.net/gettokeninfo`, {
        params: { address: token1Address },
      });
      console.log('Fetched token info:', response.data); // Debugging log
      setTokenInfo(response.data);
      setTokenDecimals(response.data.decimals || 0);
      setError(null);
    } catch (error) {
      console.error('Error fetching token info:', error);
      setHolderError('Failed to fetch token info.');
      setTokenInfo(null);
      setTokenDecimals(0);
    } finally {
      setLoadingTokenInfo(false);
    }
  }, [token1Address]);

  // Fetch data when component mounts and when dependencies change
  useEffect(() => {
    if (selectedPairAddress) {
      fetchHistoricalData15Days(selectedPairAddress);
      fetchTradeData(selectedPairAddress);
    }

    if (token1Address) {
      fetchTokenInfo();
    }

    if (selectedAddress) {
      fetchAnsProfile(selectedAddress);
    }
  }, [
    selectedPairAddress,
    token1Address,
    selectedAddress,
    fetchHistoricalData15Days,
    fetchTradeData,
    fetchTokenInfo,
    fetchAnsProfile,
  ]);

  useEffect(() => {
    if (fetchAllTradeData && selectedPairAddress) {
      fetchAllHistoricalData(selectedPairAddress);
    }
  }, [fetchAllTradeData, selectedPairAddress, fetchAllHistoricalData]);

  // Add default data for testing if historicalData is empty
  useEffect(() => {
    if (historicalData.length === 0) {
      const defaultData = [
        { time: 1627776000, open: 100, high: 110, low: 90, close: 105, volume: 1000 },
        { time: 1627862400, open: 105, high: 115, low: 95, close: 110, volume: 1500 },
        { time: 1627948800, open: 110, high: 120, low: 100, close: 115, volume: 2000 },
        // Add more data points as needed
      ];
      setHistoricalData(defaultData);
      console.log('Set default historical data for testing.');
    }
  }, [historicalData]);

  // Initialize the chart once
  useEffect(() => {
    if (!chartContainerRef.current) return;

    const chartHeight = 250; // Adjust height as needed

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartHeight,
      layout: {
        background: { type: ColorType.Solid, color: '#000000' },
        textColor: '#FFFFFF', // White text
      },
      grid: {
        vertLines: { color: 'rgba(42, 46, 57, 0.5)' },
        horzLines: { color: 'rgba(42, 46, 57, 0.5)' },
      },
      crosshair: {
        mode: 0,
        vertLine: {
          color: '#FFFFFF', // White vertical line
          width: 1,
          labelVisible: true,
          labelBackgroundColor: '#FFFFFF', // White label background
        },
        horzLine: {
          color: '#FFFFFF', // White horizontal line
          width: 1,
          labelVisible: true,
          labelBackgroundColor: '#FFFFFF', // White label background
        },
      },
      priceScale: {
        borderColor: '#FFFFFF', // White border
        tickMarkColor: '#FFFFFF', // White tick marks
        labelColor: '#FFFFFF', // White labels
        priceFormat: {
          type: 'price',
          precision: 4,
          minMove: 0.0001,
        },
      },
      timeScale: {
        borderColor: '#FFFFFF', // White border
        tickMarkColor: '#FFFFFF', // White tick marks
        labelColor: '#FFFFFF', // White labels
        timeVisible: true,
        secondsVisible: false,
        rightOffset: 10,
        barSpacing: 15,
        minBarSpacing: 1,
      },
    });

    const series = chart.addCandlestickSeries({
      upColor: '#00ff00',
      downColor: '#ff0000',
      borderUpColor: '#00ff00',
      borderDownColor: '#ff0000',
      wickUpColor: '#00ff00',
      wickDownColor: '#ff0000',
    });

    seriesRef.current = series; // Assign series to the seriesRef

    chartRef.current = chart;

    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartHeight,
        });
        chartRef.current.timeScale().fitContent();
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove(); // Clean up the chart instance
    };
  }, []); // Empty dependency array ensures this runs once

  // Update chart data when historicalData changes
  useEffect(() => {
    if (seriesRef.current && historicalData.length) {
      console.log('Updating chart with data:', historicalData);
      seriesRef.current.setData(historicalData);
      chartRef.current.timeScale().fitContent();
    } else {
      console.warn('SeriesRef is null or historicalData is empty.');
    }
  }, [historicalData]);

  const toggleShowMoreTransactions = () => {
    setShowAllTransactions((prev) => !prev);
  };

  const transactionsToShow = showAllTransactions
    ? tradeData
    : tradeData.slice(0, 10); // Limit to 10 transactions

  const handleScreenshotTaken = (dataURL) => {
    setScreenshotURL(dataURL);
    console.log('Screenshot captured:', dataURL);
    // Implement screenshot functionality as needed
  };

  // Extract ALPH price from tokenPrices
  const alphPrice = tokenPrices['ALPH'] || 0;

  return (
    <div className="token-details-container">
      <div className="left-section">
        {/* Token Logos, Name, and Social Icons */}
        <div className="token-header">
          <FaShareAlt
            className="share-icon"
            onClick={handleScreenshotTaken}
            title="Take Screenshot"
          />
          <img
            src={alphLogo}
            alt={token0?.symbol ?? 'ALPH'}
            className="token-logo"
          />
          <img
            src={tokenInfo?.logo || 'https://via.placeholder.com/50?text=No+Logo'}
            alt={tokenInfo?.symbol || 'Token Logo'}
            className="token-logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/50?text=No+Logo';
              console.log(`Failed to load logo for ${tokenInfo?.symbol}, using default.`);
            }}
          />
          <h3 className="token-name white-text">
            {tokenInfo?.name || 'Unknown Token'} ({tokenInfo?.symbol || 'N/A'})
          </h3>

          {/* Social Icons */}
          <div className="social-icons">
            {tokenInfo?.website && (
              <a href={tokenInfo.website} target="_blank" rel="noopener noreferrer">
                <FaGlobe style={{ fontSize: '20px', cursor: 'pointer' }} title="Website" />
              </a>
            )}
            {tokenInfo?.twitter && (
              <a href={tokenInfo.twitter} target="_blank" rel="noopener noreferrer">
                <FaTwitter style={{ fontSize: '20px', cursor: 'pointer' }} title="Twitter" />
              </a>
            )}
            {tokenInfo?.discord && (
              <a href={tokenInfo.discord} target="_blank" rel="noopener noreferrer">
                <FaDiscord style={{ fontSize: '20px', cursor: 'pointer' }} title="Discord" />
              </a>
            )}
          </div>
        </div>

        {/* Chart and Toolbar */}
        <div className="chart-and-toolbar-container">
          <div ref={chartContainerRef} className="chart-container"></div>
          <div className="buttons-toolbar token-details-toolbar">
            <div className="toolbar-buttons">
              {/* Toolbar buttons */}
              <button
                className={`toolbar-button ${showDOHvotePopup ? 'active' : ''}`}
                onClick={toggleDOHvotePopup}
                title="Vote"
              >
                Vote
              </button>
              <button
                className={`toolbar-button ${showHolders ? 'active' : ''}`}
                onClick={toggleHoldersVisibility}
                title="Toggle Holders"
              >
                Holders
              </button>
              {/* Calc Button */}
              <button
                className={`toolbar-button ${showALPHCalculator ? 'active' : ''}`}
                onClick={() => setShowALPHCalculator((prev) => !prev)}
                title="Calculator"
              >
                Calc
              </button>
            </div>
            {/* Enhanced Vote Result Bar */}
            <div className="vote-result-bar" aria-label="Vote results">
              {votes && (votes.bullishVotes + votes.bearishVotes) > 0 ? (
                <>
                  <div className="vote-segments">
                    <div
                      className="vote-segment bullish"
                      style={{
                        width: `${(votes.bullishVotes / (votes.bullishVotes + votes.bearishVotes)) * 100}%`,
                      }}
                      title={`${votes.bullishVotes} Bullish Votes`}
                    >
                      <span className="vote-label">
                        {`${((votes.bullishVotes / (votes.bullishVotes + votes.bearishVotes)) * 100).toFixed(1)}% Bullish`}
                      </span>
                    </div>
                    <div
                      className="vote-segment bearish"
                      style={{
                        width: `${(votes.bearishVotes / (votes.bullishVotes + votes.bearishVotes)) * 100}%`,
                      }}
                      title={`${votes.bearishVotes} Bearish Votes`}
                    >
                      <span className="vote-label">
                        {`${((votes.bearishVotes / (votes.bullishVotes + votes.bearishVotes)) * 100).toFixed(1)}% Bearish`}
                      </span>
                    </div>
                  </div>
                  <span className="total-votes">{`${votes.bullishVotes + votes.bearishVotes} votes`}</span>
                </>
              ) : (
                <div className="no-votes">No votes yet</div>
              )}
            </div>
          </div>
        </div>

        {/* DOHvote Popup */}
        {showDOHvotePopup && tokenInfo && (
          <div className="dohvote-popup">
            <DOHvote
              token1Address={token1Address}
              tokenName={tokenInfo.symbol || 'Token'}
              tokenLogo={tokenInfo.logo || alphLogo}
              onVotesUpdate={(symbol, newBullishVotes, newBearishVotes) => {
                onVotesUpdate(symbol, newBullishVotes, newBearishVotes);
              }} // Pass the callback from parent
              currentVotes={votes} // Pass the current votes as an object
            />
          </div>
        )}

        {/* ALPHCalculator Component */}
        {showALPHCalculator && (
          <ALPHCalculator
            alphPrice={alphPrice} // Pass ALPH price
            tokenPrices={tokenPrices} // Pass the tokenPrices prop
            tokenList={tokenList} // Pass the tokenList prop
            onClose={() => setShowALPHCalculator(false)} // Handle close
          />
        )}

        {/* Token Info */}
        {tokenInfo && (
          <div className="token-info">
            <div>
              <img
                src={tokenInfo.logo || 'https://via.placeholder.com/50?text=No+Logo'}
                alt={tokenInfo.name || 'Token Logo'}
                className="token-logo"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://via.placeholder.com/50?text=No+Logo';
                  console.log(`Failed to load logo for ${tokenInfo?.symbol}, using default.`);
                }}
              />
              <p>
                <strong>Contract:</strong>{' '}
                <span className="token-cell">
                  {formatWalletAddress(tokenInfo.address)}
                </span>
              </p>
            </div>
            <div>
              <p>
                <strong>LP:</strong>{' '}
                <span className="token-cell">
                  {formatWalletAddress(selectedPairAddress)}
                </span>
              </p>
            </div>
            <div>
              <p>
                <strong>Holders:</strong> <span>{tokenInfo.holderCount}</span>
              </p>
            </div>
            <div>
              <p>
                <strong>Decimals:</strong> <span>{tokenInfo.decimals}</span>
              </p>
            </div>
          </div>
        )}

        {/* Top Holders Table and Pie Chart */}
        <div
          className={`holders-info-section ${
            showHolders ? 'holders-info-visible' : 'holders-info-hidden'
          }`}
        >
          {showHolders && token1Address && tokenInfo && (
            <div className="holders-pie-container">
              <div className="top-holders-table-container">
                <TopHoldersTable
                  tokenAddress={token1Address}
                  pairAddress={selectedPairAddress} // Ensure pairAddress is defined and passed correctly
                  onHolderSelect={handleHolderSelect}
                />
              </div>
              {/* Pie Chart is already handled inside TopHoldersTable */}
            </div>
          )}
        </div>
      </div>

      {/* Right Column: Transactions and Wallet */}
      <div className="right-section">
        <div className="transaction-table-container">
          {/* Loading Spinner Removed Temporarily */}
          {/* {loading && <p className="loading-message">Loading...</p>} */}
          {error && <p className="error-message">{error}</p>}
          {tradeData.length > 0 && (
            <table className="shared-table transaction-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Price</th>
                  <th>Value</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {transactionsToShow.map((trade, index) => (
                  <tr
                    key={index}
                    onClick={() =>
                      window.open(
                        `https://explorer.alephium.org/transactions/${trade.hash}`,
                        '_blank'
                      )
                    }
                    className={
                      index % 2 === 0 ? 'even-row' : 'odd-row'
                    } // Alternating row colors
                  >
                    <td>
                      <div
                        className={`pulsing-circle ${
                          trade.type === 'buy' ? 'buy' : 'sell'
                        }`} // Buy/Sell indicator with pulsing effect
                        title={trade.type === 'buy' ? 'Buy' : 'Sell'}
                      />
                    </td>
                    <td>{formatTokenAmount(trade.token_amount)}</td>
                    <td
                      className={
                        trade.type === 'buy'
                          ? 'positive-change'
                          : 'negative-change'
                      }
                    >
                      {formatPrice(trade.token_price)}
                    </td>
                    <td>${(trade.token_amount_usd ?? 0).toFixed(2)}</td>
                    <td>{formatTimeDifference(trade.date)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {/* Toggle Show More Transactions Button */}
          {tradeData.length > 10 && (
            <button
              onClick={toggleShowMoreTransactions}
              className="show-more-btn"
            >
              {showAllTransactions ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>

        {/* Wallet Component Below Transactions */}
        <div className="wallet-container">
          <Wallet walletAddress={selectedAddress} />
        </div>
      </div>

      {/* Overlay for DOHvote Popup */}
      {showDOHvotePopup && (
        <div className="overlay" onClick={toggleDOHvotePopup}></div>
      )}
    </div>
  );
};

// Define PropTypes for TokenDetails Component
TokenDetails.propTypes = {
  selectedPairAddress: PropTypes.string.isRequired,
  token1Address: PropTypes.string.isRequired,
  token0: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    // Add other token0 properties if needed
  }).isRequired,
  walletAddress: PropTypes.string,
  onVotesUpdate: PropTypes.func.isRequired, // Ensure onVotesUpdate is passed and required
  votes: PropTypes.shape({
    bullishVotes: PropTypes.number,
    bearishVotes: PropTypes.number,
  }).isRequired, // Now correctly passed as an object
  tokenList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      logoURI: PropTypes.string,
      totalSupply: PropTypes.number, // Ensure totalSupply is part of tokenList
    })
  ).isRequired, // New prop for tokenList
  tokenPrices: PropTypes.object.isRequired, // New prop for tokenPrices
};

export default React.memo(TokenDetails);
