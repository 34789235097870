import React, { createContext, useContext, useState } from 'react';

const WalletModalContext = createContext();

export const WalletModalProvider = ({ children }) => {
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const handleOpenModal = () => setIsConnectModalOpen(true);
  const handleCloseModal = () => setIsConnectModalOpen(false);

  return (
    <WalletModalContext.Provider value={{ isConnectModalOpen, handleOpenModal, handleCloseModal }}>
      {children}
    </WalletModalContext.Provider>
  );
};

export const useWalletModal = () => {
  return useContext(WalletModalContext);
};
