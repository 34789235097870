import React from 'react';
import WorkflowStatus from './workflowstatus'; // Assuming the WorkflowStatus component you created earlier is in the components folder

const WorkflowStatusPage = () => {
  return (
    <div>
      <h1>Workflow Status and Docker Versions</h1>
      <WorkflowStatus />
    </div>
  );
};

export default WorkflowStatusPage;
