import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './auth';
import Navbar from './navbar';
import Sidebar from './sidebar';
import Footer from './footer';
import NodeManager from './nodemanager/nodemanager';
import HomePage from './homepage';
import Tokens from './tokens/tokens';
import WorkflowStatusPage from './nodemanager/workflowstatuspage';
import './app.css';
import customTheme from './customTheme';
import { AlephiumWalletProvider } from '@alephium/web3-react';
import DOHmail from './dohmail/dohmail';
import NodeDetails from './nodemanager/nodedetails';
import Pools from './tokens/pools';
import Wallet from './tokens/wallet';
import TokenCatcher from './tokens/tokencatcher';
import Poker from './games/poker/poker';
import { WalletModalProvider } from './walletmodalcontext'; 
import WalletConnectModal from './walletconnectmodal'; 
import TokenBubbles from './tokens/tokenbubbles';
import MintUploader from './mint/mintuploader';
import ProfitLoss from './tokens/profitloss';
import DOHnut from './utils/donut';
import TestBalances from './tokens/testbalances';
import HistoricalTrades from './tokens/historicaltrades';
const queryClient = new QueryClient();


const walletProviderConfig = {
  network: 'mainnet',
  rpcUrl: 'https://rpc.alephium.org',
};

const applyTheme = (theme) => {
  const root = document.documentElement;
  Object.keys(theme).forEach((key) => {
    root.style.setProperty(key, theme[key]);
  });
};

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    applyTheme(customTheme);
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <AlephiumWalletProvider config={walletProviderConfig}>
      <WalletModalProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <BrowserRouter>
              <div className="app-container">
                <Navbar onSearch={handleSearch} />
                <Sidebar />
                <div className="main-content">
                  <Routes>
                    <Route path="/nodes" element={<NodeManager />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/tokens" element={<Tokens searchQuery={searchQuery} />} />
                    <Route path="/tokencatcher" element={<TokenCatcher />} />
                    <Route path="/poker" element={<Poker />} />
                    <Route path="/" element={<Tokens searchQuery={searchQuery} />} />
                    <Route path="/workflow-status" element={<WorkflowStatusPage />} />
                    <Route path="/tokenbubbles" element={<TokenBubbles />} />
                    <Route path="/mintuploader" element={<MintUploader />} />
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/profitloss" element={<ProfitLoss />} /> {/* New CoingeckoAPI route */}
                    <Route path="/dohnut" element={<DOHnut/>} />
                    <Route path="/balances" element={<TestBalances />} />
                    <Route path="/historicaltrades" element={<HistoricalTrades/>} /> 

                  </Routes>
                </div>
                <Footer />
              </div>
              <WalletConnectModal />
            </BrowserRouter>
          </AuthProvider>
        </QueryClientProvider>
      </WalletModalProvider>
    </AlephiumWalletProvider>
  );
};

export default App;
