import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js'; // Importing crypto-js for encryption

// Modal Component Defined Outside MintUploader
const Modal = ({ children, onClose }) => {
  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        {children}
        <button onClick={onClose} style={styles.closeButton}>
          Close
        </button>
      </div>
    </div>
  );
};

// CollapsibleSection Component Defined Outside MintUploader
const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div style={styles.collapsibleContainer}>
      <div
        style={styles.collapsibleHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title} <span style={styles.toggleIcon}>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div style={styles.collapsibleContent}>{children}</div>}
    </div>
  );
};

// BlurredNumber Component (Always Blurred, No Reveal Functionality)
const BlurredNumber = ({ number }) => {
  return (
    <span
      style={{
        filter: 'blur(4px)',
        cursor: 'default',
        transition: 'filter 0.3s ease',
      }}
      aria-label={`Number ${number}`}
      title={`Number ${number}`} // Tooltip for accessibility
    >
      {number}
    </span>
  );
};

const MintUploader = () => {
  const [baseUrl, setBaseUrl] = useState(
    'https://dfsz7q4qbubdlaew75hczekwihziad3o4f5dvaesdibbys7zaauq.arweave.net/GWWfw5ANAjWAlv9OLJFWQfKAD27hejqAkhoCHEv5ACk/'
  );
  const [numberOfNFTs, setNumberOfNFTs] = useState(100);
  const [status, setStatus] = useState('');
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [manifestUrl, setManifestUrl] = useState('');
  const [hash, setHash] = useState(''); // State for storing the hash
  const [encryptionSteps, setEncryptionSteps] = useState([]); // State for displaying encryption steps
  const [randomizationSteps, setRandomizationSteps] = useState([]); // State for displaying randomization steps
  const [originalTraitsList, setOriginalTraitsList] = useState([]); // State for storing original NFT data
  const [isDocOpen, setIsDocOpen] = useState(false); // State for documentation modal

  // Function to encrypt data using AES with step-by-step visualization
  const encryptData = (data, key) => {
    const dataString = JSON.stringify(data);
    const ciphertext = CryptoJS.AES.encrypt(dataString, key).toString();

    // Create encryption steps
    const steps = [
      { action: 'Original Data', value: dataString },
      { action: 'Encryption Key', value: key },
      { action: 'Encrypted Output', value: ciphertext },
    ];

    setEncryptionSteps(steps);
    return ciphertext;
  };

  // Upload encrypted data to the server
  const uploadToServer = async (encryptedData) => {
    try {
      setStatus('Uploading encrypted data...');
      setProgress(70); // Update progress to indicate uploading
      const response = await fetch(process.env.REACT_APP_UPLOAD_URL || 'https://dohcloud.azurewebsites.net/UploadNFTData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: encryptedData }), // Send encrypted data
      });

      if (response.ok) {
        const result = await response.json();
        setStatus(result.message || 'Data uploaded successfully.');
        setProgress(100); // Completion of the process

        // Replace default domain with custom domain
        if (result.manifestUrl) {
          const customManifestUrl = result.manifestUrl.replace(
            'https://dpflnodestore.blob.core.windows.net',
            'https://store.doh.money'
          );
          setManifestUrl(customManifestUrl);
        }
      } else {
        const errorText = await response.text();
        setError(`Error uploading data: ${errorText}`);
        setProgress(0);
      }
    } catch (error) {
      console.error('Error uploading data:', error);
      setError(`Error uploading data: ${error.message}`);
      setProgress(0);
    }
  };

  // Fetch and randomize NFT data
  const fetchAndRandomizeNFTData = async () => {
    const traitsList = [];
    const steps = []; // Temporary array to hold randomization steps

    for (let i = 1; i <= numberOfNFTs; i++) {
      const url = `${baseUrl}${i}`;
      try {
        const response = await axios.get(url);
        traitsList.push({
          nftNumber: i,
          data: response.data,
        });
        setProgress(Math.round((i / numberOfNFTs) * 30)); // Up to 30% for fetching
      } catch (error) {
        console.error(`Error fetching traits for NFT ${i}:`, error.message);
        setError(`Error fetching traits for NFT ${i}: ${error.message}`);
        setProgress(0);
        return null;
      }
    }

    try {
      setOriginalTraitsList([...traitsList]); // Store original data before randomization
      setStatus('Randomizing traits...');
      // Fisher-Yates Shuffle with step tracking
      for (let i = traitsList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [traitsList[i], traitsList[j]] = [traitsList[j], traitsList[i]];

        // Push a step object
        steps.push({
          action: 'Swapped',
          nft1: traitsList[i].nftNumber,
          nft2: traitsList[j].nftNumber,
        });

        setProgress(Math.round(30 + ((traitsList.length - i) / traitsList.length) * 30)); // 30-60% for randomizing
      }
      setRandomizationSteps(steps); // Update state with randomization steps

      // Generate a cryptographic hash for the randomized data
      const dataString = JSON.stringify(traitsList);
      const hashValue = CryptoJS.SHA256(dataString).toString();
      setHash(hashValue);

      // Encrypt the data with a secret key (should match the backend key)
      const secretKey = 'your-strong-secret-key'; // Replace with a secure key, handle securely in production
      const encryptedData = encryptData(traitsList, secretKey);
      // setEncryptedOutput(encryptedData); // Optional: Store encrypted output for display

      return encryptedData; // Return encrypted data
    } catch (error) {
      console.error('Error during randomization:', error.message);
      setError(`Error during randomization: ${error.message}`);
      setProgress(0);
      return null;
    }
  };

  // Handle the process to fetch, randomize, encrypt, and upload data
  const handleProcess = async () => {
    // Input Validation
    if (numberOfNFTs < 1 || numberOfNFTs > 100) {
      setError('Number of NFTs must be between 1 and 100.');
      return;
    }

    if (!validateUrl(baseUrl)) {
      setError('Please enter a valid Base URL.');
      return;
    }

    setError('');
    setStatus('Starting the process...');
    setProgress(0);
    setManifestUrl('');
    setHash('');
    setRandomizationSteps([]);
    setEncryptionSteps([]);
    setOriginalTraitsList([]); // Reset original traits list
    // setEncryptedOutput(''); // Optional: Reset encrypted output

    const encryptedData = await fetchAndRandomizeNFTData();

    if (encryptedData) {
      await uploadToServer(encryptedData); // Upload the encrypted data
    }
  };

  // Validate URL
  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div style={styles.container}>
      <h2>NFT Traits Randomizer & Uploader</h2>
      <div style={styles.formGroup}>
        <label htmlFor="baseUrl">Base URL:</label>
        <input
          id="baseUrl"
          type="text"
          value={baseUrl}
          onChange={(e) => setBaseUrl(e.target.value)}
          style={styles.input}
          placeholder="Enter base URL"
        />
      </div>
      <div style={styles.formGroup}>
        <label htmlFor="numberOfNFTs">Number of NFTs (1-100):</label>
        <input
          id="numberOfNFTs"
          type="number"
          value={numberOfNFTs}
          onChange={(e) => setNumberOfNFTs(Number(e.target.value))}
          style={styles.input}
          min="1"
          max="100"
        />
      </div>
      {error && <p style={styles.error}>{error}</p>}
      <button onClick={handleProcess} style={styles.button}>
        Start Process
      </button>
      {status && <p style={styles.status}>{status}</p>}
      {manifestUrl && (
        <div style={styles.result}>
          <h3>Manifest URL:</h3>
          <a href={manifestUrl} target="_blank" rel="noopener noreferrer" style={styles.link}>
            {manifestUrl}
          </a>
        </div>
      )}
      {hash && (
        <div style={styles.result}>
          <h3>Randomization Confirmation Hash:</h3>
          <p>{hash}</p>
        </div>
      )}
      {originalTraitsList.length > 0 && (
        <CollapsibleSection title="Original Data">
          <ul style={styles.scrollableList}>
            {originalTraitsList.map((trait) => (
              <li key={trait.nftNumber} style={styles.listItem}>
                NFT <BlurredNumber number={trait.nftNumber} />: {JSON.stringify(trait.data)}
              </li>
            ))}
          </ul>
        </CollapsibleSection>
      )}
      {randomizationSteps.length > 0 && (
        <CollapsibleSection title="Randomization Steps">
          <ul style={styles.scrollableList}>
            {randomizationSteps.map((step, index) => (
              <li key={index} style={styles.listItem}>
                {step.action} NFT{' '}
                <BlurredNumber number={step.nft1} /> with NFT{' '}
                <BlurredNumber number={step.nft2} />
              </li>
            ))}
          </ul>
        </CollapsibleSection>
      )}
      {encryptionSteps.length > 0 && (
        <CollapsibleSection title="Encryption Steps">
          <ul style={styles.scrollableList}>
            {encryptionSteps.map((step, index) => (
              <li key={index} style={styles.listItem}>
                <strong>{step.action}:</strong> {step.value}
              </li>
            ))}
          </ul>
        </CollapsibleSection>
      )}
      {/* Removed Encrypted Output Display for Security */}
      {progress > 0 && (
        <div style={styles.progressBarContainer}>
          <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
        </div>
      )}
      {/* Removed Mint NFT Button */}
      <button onClick={() => setIsDocOpen(true)} style={styles.docButton}>
        Documentation
      </button>

      {isDocOpen && (
        <Modal onClose={() => setIsDocOpen(false)}>
          <h2>Documentation</h2>
          <p>
            <strong>Randomization Process:</strong>
            <br />
            The NFT traits are fetched from the provided base URL. Each NFT's traits are retrieved via HTTP GET requests. After fetching all traits, the data is randomized using the Fisher-Yates shuffle algorithm. Each swap during the shuffle is recorded as a step and displayed to provide transparency in the randomization process without revealing the final order of NFTs.
          </p>
          <p>
            <strong>Encryption Process:</strong>
            <br />
            Once the traits are randomized, the entire dataset is encrypted using AES encryption with a secret key. This ensures that the NFT data remains secure and can only be accessed by authorized parties who possess the decryption key. The encryption steps are recorded and displayed for transparency.
          </p>
          <p>
            <strong>Uploading:</strong>
            <br />
            The encrypted data is then uploaded to the designated server endpoint. Upon successful upload, a manifest URL is generated. This URL serves as a reference to the stored encrypted data and is essential for future operations like minting NFTs.
          </p>
          <p>
            <strong>Progress Indicators:</strong>
            <br />
            A progress bar visually represents the stages of the process: fetching traits, randomizing traits, encrypting data, and uploading data. Additionally, detailed logs of randomization and encryption steps are available in collapsible sections for users who wish to review the process.
          </p>
          <button onClick={() => setIsDocOpen(false)} style={styles.closeButton}>
            Close
          </button>
        </Modal>
      )}
    </div>
  );
};

// Inline Styles (Consider using CSS Modules or styled-components for larger projects)
const styles = {
  container: {
    backgroundColor: '#1a1a1a', // Slightly lighter black for better readability
    color: '#f0f0f0', // Light text color for contrast
    minHeight: '100vh',
    padding: '20px',
    maxWidth: '800px', // Increased maxWidth for better layout on larger screens
    margin: '0 auto',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.5)',
    position: 'relative',
  },
  formGroup: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    padding: '0.75rem',
    marginTop: '0.5rem',
    borderRadius: '5px',
    border: '1px solid #555',
    backgroundColor: '#2a2a2a',
    color: '#f0f0f0',
  },
  button: {
    padding: '0.75rem 1rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    marginTop: '1rem',
    width: '100%',
    maxWidth: '300px',
    alignSelf: 'center',
    transition: 'background-color 0.3s ease',
  },
  docButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#2196F3',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    marginTop: '1rem',
    width: '100%',
    maxWidth: '300px',
    alignSelf: 'center',
    transition: 'background-color 0.3s ease',
  },
  status: {
    marginTop: '1rem',
    fontStyle: 'italic',
    textAlign: 'center',
    color: '#81c784', // Green color for success messages
  },
  error: {
    color: '#e57373', // Red color for error messages
    marginBottom: '1rem',
    textAlign: 'center',
  },
  result: {
    marginTop: '1rem',
    textAlign: 'center',
    wordWrap: 'break-word',
  },
  link: {
    color: '#81d4fa', // Light blue for links
    textDecoration: 'underline',
  },
  progressBarContainer: {
    width: '100%',
    backgroundColor: '#555',
    borderRadius: '5px',
    overflow: 'hidden',
    marginTop: '1rem',
  },
  progressBar: {
    height: '20px',
    backgroundColor: '#81c784',
    width: '0%',
    transition: 'width 0.5s ease-in-out',
  },
  scrollable: {
    backgroundColor: '#2a2a2a',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'left',
    overflow: 'auto',
    maxHeight: '300px', // Increased maxHeight for better readability
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  scrollableList: {
    listStyleType: 'none',
    padding: 0,
    maxHeight: '300px', // Increased maxHeight for better readability
    overflowY: 'auto',
    margin: 0,
  },
  listItem: {
    textAlign: 'left',
    padding: '5px 0',
    borderBottom: '1px solid #444',
  },
  collapsibleContainer: {
    marginTop: '1rem',
    border: '1px solid #555',
    borderRadius: '5px',
    overflow: 'hidden',
    backgroundColor: '#1e1e1e',
  },
  collapsibleHeader: {
    padding: '10px 15px',
    backgroundColor: '#333',
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    userSelect: 'none',
  },
  toggleIcon: {
    marginLeft: '10px',
    fontSize: '1.2rem',
  },
  collapsibleContent: {
    padding: '10px 15px',
    backgroundColor: '#2a2a2a',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)', // Darker overlay for better focus
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    padding: '20px', // Padding for smaller screens
  },
  modalContent: {
    backgroundColor: '#2a2a2a',
    padding: '20px',
    borderRadius: '10px',
    width: '100%',
    maxWidth: '600px', // Increased maxWidth for more content
    color: '#f0f0f0',
    position: 'relative',
    maxHeight: '90vh', // Ensure modal doesn't exceed viewport height
    overflowY: 'auto', // Enable scrolling within the modal
  },
  closeButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#e57373',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    marginTop: '1rem',
    display: 'block',
    marginLeft: 'auto',
    transition: 'background-color 0.3s ease',
  },
};

export default MintUploader;
