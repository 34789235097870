import React from 'react';
import './app.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-text">
        © 2024 doh.money. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
