// src/utils/handevaluator.js

import { Hand } from 'poker-hand-evaluator';

// Function to evaluate the hand value
export const evaluateHand = (cards) => {
  // Convert card objects to the expected format, like ['AS', 'KH', 'QC', 'JD', '10H']
  const formattedCards = cards.map(card => `${card.value}${card.suit[0].toUpperCase()}`);

  try {
    const hand = new Hand(formattedCards);
    
    // Return an object with rank, hand type, and the best hand description
    return {
      rank: hand.rank,
      type: hand.name, // The hand type, e.g., "Flush", "Full House"
      description: hand.descr, // A detailed description, e.g., "Flush, Ace high"
    };
  } catch (error) {
    console.error('Error evaluating hand:', error);
    // Return a default value if evaluation fails
    return {
      rank: -1,
      type: 'Invalid Hand',
      description: 'Unable to evaluate',
    };
  }
};
