const colors = {
  white: '#FDFEE9',
  black: '#000000',  // Pure black
  baseYellow: '#FFD700',  // Gold yellow
  lightYellow: '#F8DA8B',
  highlightYellow: '#FFD700',  // Bright yellow (matches base)
  mutedText: '#9196A1',
  mutedTextHover: '#111111',
  overlayBackground: 'rgba(0, 0, 0, 0.8)',  // Dark overlay for modals
  secondaryBackground: '#333333',  // Dark grey for secondary elements
  secondaryBackgroundHover: '#444444',  // Lighter dark grey on hover
  secondaryOutline: '#FFD700',  // Yellow outline to match theme
  tertiaryBackground: '#222222',  // Very dark grey
  dividerColor: '#FFD700',  // Yellow dividers
  dangerColor: '#FF4E4E',  // Red for danger/alerts
  validColor: '#32D74B',  // Green for valid input
  siweBorder: '#FFD700',  // Yellow border for SIWE
};

const theme = {
  '--ck-font-family': '"Open Sans", sans-serif',
  '--ck-connectbutton-font-size': '1.25rem',
  '--ck-primary-button-font-family': '"Open Sans", sans-serif',

  '--ck-border-radius': '8px',

  // Button Colors and Styles
  '--ck-connectbutton-color': colors.highlightYellow,  // Yellow font color
  '--ck-connectbutton-background': colors.black,  // Black background inside button
  '--ck-connectbutton-box-shadow': `0 0 5px ${colors.highlightYellow}`,  // Smaller glow effect
  '--ck-connectbutton-border-color': colors.highlightYellow,  // Yellow border

  '--ck-connectbutton-hover-background': colors.secondaryBackgroundHover,  // Lighter grey background on hover
  '--ck-connectbutton-hover-box-shadow': `0 0 8px ${colors.highlightYellow}`,  // Slightly stronger glow on hover

  '--ck-primary-button-color': colors.highlightYellow,  // Button text yellow
'--ck-primary-button-background': `${colors.black} !important`, 

  '--ck-primary-button-box-shadow': `0 0 5px ${colors.highlightYellow}`,  // Smaller glowing yellow border

  '--ck-primary-button-hover-background': colors.secondaryBackgroundHover,  // Slightly lighter black on hover
  '--ck-primary-button-hover-box-shadow': `0 0 8px ${colors.highlightYellow}`,  // Slightly stronger glow on hover

  '--ck-secondary-button-color': colors.highlightYellow,  // Yellow text on secondary buttons
  '--ck-secondary-button-background': colors.black,  // Black background
  '--ck-secondary-button-box-shadow': `0 0 5px ${colors.highlightYellow}`,  // Smaller glowing yellow border

  '--ck-secondary-button-hover-background': colors.secondaryBackgroundHover,  // Lighter black on hover
  '--ck-secondary-button-hover-box-shadow': `0 0 8px ${colors.highlightYellow}`,  // Slightly stronger glow on hover

  '--ck-tooltip-background': colors.black,  // Tooltip black
  '--ck-tooltip-color': colors.highlightYellow,  // Tooltip yellow text
  '--ck-tooltip-shadow': 'none',

  '--ck-spinner-color': colors.highlightYellow,  // Spinner yellow

  '--ck-dropdown-button-color': colors.highlightYellow,  // Dropdown button text yellow
  '--ck-dropdown-button-background': colors.black,  // Dropdown button background black
  '--ck-dropdown-button-hover-background': colors.secondaryBackgroundHover,

  '--ck-dropdown-color': colors.highlightYellow,  // Dropdown text color yellow
  '--ck-dropdown-box-shadow': 'none',

  '--ck-alert-color': colors.white,
  '--ck-alert-background': colors.dangerColor,  // Danger red for alerts
  '--ck-alert-box-shadow': `0 0 5px ${colors.alertShadow}`,  // Soft shadow
  '--ck-alert-border-radius': '8px',

  '--ck-qr-border-radius': '12px',
  '--ck-qr-dot-color': '#2E3138',  // Dark grey dots for QR code
  '--ck-qr-border-color': colors.highlightYellow,  // Glowing yellow border for QR

  '--ck-linear-gradient': `linear-gradient(to bottom, ${colors.black} 0%, ${colors.secondaryBackground} 100%)`,  // Black gradient

  /** Modal */
  '--ck-modal-box-shadow': `0px 2px 4px ${colors.baseYellow}`,  // Soft yellow shadow
  '--ck-overlay-background': colors.overlayBackground,  // Dark overlay
  '--ck-body-color': colors.white,
  '--ck-body-color-muted': colors.mutedText,
  '--ck-body-color-muted-hover': colors.mutedTextHover,
  '--ck-body-background': colors.black,
  '--ck-body-background-transparent': 'rgba(255,255,255,0)',
  '--ck-body-background-secondary': colors.secondaryBackground,
  '--ck-body-background-secondary-hover-background': colors.secondaryBackgroundHover,
  '--ck-body-background-secondary-hover-outline': colors.secondaryOutline,
  '--ck-body-background-tertiary': colors.tertiaryBackground,

  /** Action Color is used for the close action */
  '--ck-body-action-color': colors.white,
  '--ck-body-divider': colors.dividerColor,  // Yellow divider
  '--ck-body-color-danger': colors.dangerColor,
  '--ck-body-color-valid': colors.validColor,
  '--ck-siwe-border': colors.siweBorder,
};

export default theme;
