// src/components/tokens/tokens.js

import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useQuery, useQueries } from '@tanstack/react-query';
import { FaFilter, FaTable, FaTh, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import FeedbackModal from '../toolbar/feedbackbot';
import Filter from '../toolbar/filter';
import TokenDetails from './tokendetails';
import TokenTicker from './tokenticker';
import TokenBubbles from './tokenbubbles';
import './tokens.css';
import axios from 'axios';
import alphLogo from '../assets/logos/ALPH.png';
import binanceLogo from '../assets/logos/binance.png';
import solanaLogo from '../assets/logos/solana.png';
import ethereumLogo from '../assets/logos/ethereum.png';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import './tokens.mobile.css';

Modal.setAppElement('#root');

const defaultLogoURL = 'https://via.placeholder.com/150?text=No+Logo';

// Configuration for supported chains
const chains = {
  Alephium: {
    apiUrl: 'https://api.mobula.io/api/1/market/blockchain/pairs?blockchain=Alephium', // Replace with actual API
    tokenListUrl: 'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json', // Replace with actual token list URL
    logo: alphLogo,
    baseTokenSymbol: 'ALPH',
  },
  Binance: {
    apiUrl: 'https://api.binance.org/api/1/market/blockchain/pairs?blockchain=Binance', // Replace with actual API
    tokenListUrl: 'https://raw.githubusercontent.com/binance/token-list/master/tokens/mainnet.json', // Replace with actual token list URL
    logo: binanceLogo,
    baseTokenSymbol: 'BNB',
  },
  Solana: {
    apiUrl: 'https://api.solana.io/api/1/market/blockchain/pairs?blockchain=Solana', // Replace with actual API
    tokenListUrl: 'https://raw.githubusercontent.com/solana/token-list/master/tokens/mainnet.json', // Replace with actual token list URL
    logo: solanaLogo,
    baseTokenSymbol: 'SOL',
  },
  Ethereum: {
    apiUrl: 'https://api.ethereum.io/api/1/market/blockchain/pairs?blockchain=Ethereum', // Replace with actual API
    tokenListUrl: 'https://raw.githubusercontent.com/ethereum/token-list/master/tokens/mainnet.json', // Replace with actual token list URL
    logo: ethereumLogo,
    baseTokenSymbol: 'ETH',
  },
};

const nonAlphTokens = ['Bitcoin', 'Ethereum', 'USDC', 'Tether']; // Adjust based on chain context if needed

// Fetch token list based on selected chain
const fetchTokenList = async (selectedChain) => {
  try {
    const { tokenListUrl } = chains[selectedChain];
    const response = await axios.get(tokenListUrl);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch token list: Status ${response.status}`);
    }

    const tokens = response.data.tokens || response.data.data?.tokens || [];

    if (!Array.isArray(tokens)) {
      console.error('Token list is not an array:', tokens);
      return [];
    }

    return tokens;
  } catch (error) {
    console.error('Error fetching token list:', error);
    return [];
  }
};

// Fetch token pairs based on selected chain
const fetchTokenPairs = async (selectedChain, apiKey) => {
  try {
    const { apiUrl, baseTokenSymbol } = chains[selectedChain];
    const response = await axios.get(apiUrl, {
      headers: { Authorization: apiKey },
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch token pairs: Status ${response.status}`);
    }

    const data = response.data;

    if (!Array.isArray(data.data)) {
      console.error('Token pairs data is not an array:', data.data);
      return [];
    }

    return data.data
      .filter(entry => entry.pair.token0.symbol === baseTokenSymbol)
      .map(entry => {
        const {
          pair: { token0, token1, volume24h, liquidity, address: pairAddress, dex }, // Assuming 'dex' field exists
          price,
          price_change_5min,
          price_change_1h,
          price_change_4h,
          price_change_24h,
        } = entry;

        // Manually set total supply for specific tokens if needed
        let totalSupply = token1.totalSupply ?? 0;
        if (token1.symbol === 'APAD') {
          totalSupply = 100_000_000; // Set this value based on your needs
        }

        // Calculate market cap using total supply and price
        let marketCap = 'N/A';
        if (price !== null && totalSupply > 0) {
          marketCap = price * totalSupply;
        }

        const basePrice = token0?.price ?? 0;
        const baseTokenRatio = price !== 0 && basePrice !== 0 ? price / basePrice : 0;

        return {
          token1: token1,
          price: price ?? 0,
          baseTokenRatio,
          volume24h: volume24h ?? 0,
          liquidity: (liquidity ?? 0) * 2,
          totalSupply: totalSupply,
          marketCap: marketCap !== 'N/A' ? marketCap : 'N/A',
          price_change_5min: price_change_5min ?? 0,
          price_change_1h: price_change_1h ?? 0,
          price_change_4h: price_change_4h ?? 0,
          price_change_24h: price_change_24h ?? 0,
          pairAddress,
          token0,
          dex: dex || 'Main DEX', // Default to 'Main DEX' if dex info is missing
        };
      });
  } catch (error) {
    console.error('Error fetching token pairs:', error);
    return [];
  }
};

// Utility functions (formatting, etc.)
const formatLargeNumber = (num) => {
  if (isNaN(num) || num === null || num === undefined || num >= 1e50) {
    return 'N/A';
  }

  if (num >= 1e15) {
    return `${(num / 1e15).toFixed(2)}Q`;
  } else if (num >= 1e12) {
    return `${(num / 1e12).toFixed(2)}T`;
  } else if (num >= 1e9) {
    return `${(num / 1e9).toFixed(2)}B`;
  } else if (num >= 1e6) {
    return `${(num / 1e6).toFixed(2)}M`;
  } else if (num >= 1e3) {
    return `${(num / 1e3).toFixed(2)}K`;
  } else {
    return num.toFixed(2);
  }
};

const formatPrice = (price) => {
  if (price > 99999) {
    return 'N/A';
  }

  const priceString = price.toFixed(8);
  const splitPrice = priceString.split('.');
  let leadingZerosCount = 0;
  const decimals = splitPrice[1];

  for (let i = 0; decimals && i < decimals.length; i++) {
    if (decimals[i] === '0') {
      leadingZerosCount++;
    } else {
      break;
    }
  }

  if (leadingZerosCount > 2) {
    const remainingDecimals = decimals.slice(leadingZerosCount);
    return (
      <>
        0.0<sup style={{ fontSize: '0.6em', verticalAlign: 'super' }}>{leadingZerosCount}</sup>
        {remainingDecimals}
      </>
    );
  } else if (price >= 1000 && price < 100000) {
    return `$${price.toFixed(2)}`;
  } else if (price >= 1 && price < 1000) {
    return `$${price.toFixed(2)}`;
  } else if (price < 10) {
    return `$${price.toFixed(4)}`;
  } else if (price >= 100000) {
    return `$${(price / 1000).toFixed(2)}k`;
  } else {
    return `$${price.toFixed(4)}`;
  }
};

const formatRatio = (ratio) => {
  if (ratio > 99999) {
    return 'N/A';
  }

  if (ratio > 9.99) {
    return ratio.toFixed(0);
  }
  if (ratio < 0.0000) {
    const ratioString = ratio.toString();
    const decimalPart = ratioString.split('.')[1] || '';
    const leadingZerosCount = decimalPart.match(/^0+/) ? decimalPart.match(/^0+/)[0].length : 0;

    const remainingDecimals = decimalPart.slice(leadingZerosCount);
    const formattedDecimal = remainingDecimals.length > 2 ? remainingDecimals.slice(0, 2) : remainingDecimals;

    if (leadingZerosCount >= 4) {
      return (
        <>
          0.0<sup>{leadingZerosCount}</sup>
          {formattedDecimal}
        </>
      );
    } else {
      return `0.${formattedDecimal}`;
    }
  } else {
    return ratio.toFixed(4);
  }
};

// Liquidity Metrics Component
const LiquidityMetrics = ({ pair, averages }) => {
  const liquidityToMarketCapRatio = pair.marketCap > 0 ? (pair.liquidity / pair.marketCap) * 100 : 0;
  const volumeToLiquidityRatio = pair.liquidity > 0 ? (pair.volume24h / pair.liquidity) * 100 : 0;
  const slippageTolerance = pair.liquidity > 0 ? ((pair.liquidity - pair.volume24h) / pair.liquidity) * 100 : 0;

  return (
    <div className="metrics-container">
      <div className="metric-item">
        <h4>
          Liquidity/MC Ratio
          <span
            className="info-icon"
            title="This ratio indicates how much liquidity there is in relation to the market cap of the token. A higher ratio suggests better liquidity."
          >
            ℹ️
          </span>
        </h4>
        <p>
          {liquidityToMarketCapRatio > 0 ? `${liquidityToMarketCapRatio.toFixed(2)}%` : 'N/A'}&nbsp;/&nbsp;Avg: {averages.liquidityToMarketCap.toFixed(2)}%
        </p>
        <div className="bar-container">
          <div
            className="bar marketcap"
            style={{ width: `${pair.marketCapPercentage}%` }}
          ></div>
        </div>
      </div>

      <div className="metric-item">
        <h4>
          Volume/Liquidity Ratio
          <span
            className="info-icon"
            title="This ratio compares the trading volume to the liquidity of the token. Higher values indicate better trading activity."
          >
            ℹ️
          </span>
        </h4>
        <p>
          {volumeToLiquidityRatio > 0 ? `${volumeToLiquidityRatio.toFixed(2)}%` : 'N/A'}&nbsp;/&nbsp;Avg: {averages.volumeToLiquidity.toFixed(2)}%
        </p>
        <div className="bar-container">
          <div className="bar liquidity" style={{ width: `${volumeToLiquidityRatio}%` }}></div>
        </div>
      </div>

      <div className="metric-item">
        <h4>
          Est. Slippage Tolerance
          <span
            className="info-icon"
            title="This metric estimates the slippage in percentage when executing orders. Lower values indicate less slippage risk."
          >
            ℹ️
          </span>
        </h4>
        <p>
          {slippageTolerance > 0 ? `${slippageTolerance.toFixed(2)}%` : 'N/A'}&nbsp;/&nbsp;Avg: {averages.slippageTolerance.toFixed(2)}%
        </p>
        <div className="bar-container">
          <div className="bar volume" style={{ width: `${slippageTolerance}%` }}></div>
        </div>
      </div>
    </div>
  );
};

LiquidityMetrics.propTypes = {
  pair: PropTypes.object.isRequired,
  averages: PropTypes.object.isRequired,
};

// Custom Option Component for react-select
const OptionComponent = (props) => (
  <components.Option {...props}>
    <img
      src={props.data.logo}
      alt={props.data.label}
      style={{ width: 20, height: 20, marginRight: 10, borderRadius: '50%' }}
    />
    {props.data.label}
  </components.Option>
);

// Custom Single Value Component for react-select
const SingleValueComponent = (props) => (
  <components.SingleValue {...props}>
    <img
      src={props.data.logo}
      alt={props.data.label}
      style={{ width: 20, height: 20, marginRight: 10, borderRadius: '50%' }}
    />
    {props.data.label}
  </components.SingleValue>
);

// Utility function to abbreviate token names
const abbreviateName = (name, maxLength = 10) => {
  if (name.length <= maxLength) return name;
  return `${name.substring(0, maxLength)}...`;
};

// Main Tokens Component
const Tokens = ({ searchQuery }) => {
  const [selectedChain, setSelectedChain] = useState('Alephium');
  const [tokenList, setTokenList] = useState([]);
  const [orderBy, setOrderBy] = useState('votes'); // Default sort by votes
  const [order, setOrder] = useState('desc'); // Descending order
  const [filters, setFilters] = useState({
    hideZeroVolume: false,
    hideSub500Liquidity: false,
    hideNonAlphTokens: false,
  });
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isTokenDetailsModalOpen, setIsTokenDetailsModalOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const filterRef = useRef(null);
  const [selectedPairAddress, setSelectedPairAddress] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenPrices, setTokenPrices] = useState({});
  const [viewMode, setViewMode] = useState('table');
  const [votesMap, setVotesMap] = useState({}); // Centralized votes state
  const [previousRanks, setPreviousRanks] = useState({});
  const [rankMovements, setRankMovements] = useState({});
  const [favorites, setFavorites] = useState([]);
  const [pools, setPools] = useState([]); // New state for pools data

  const apiKey = process.env.REACT_APP_MOBULA_API_KEY; // Ensure this is set in your environment variables

  const toggleFavorite = (symbol) => {
    setFavorites((prevFavorites) =>
      prevFavorites.includes(symbol)
        ? prevFavorites.filter((fav) => fav !== symbol)
        : [...prevFavorites, symbol]
    );
  };

  // Fetch token list when selectedChain changes
  useEffect(() => {
    const fetchList = async () => {
      try {
        const tokens = await fetchTokenList(selectedChain);
        console.log("Tokens fetched:", tokens); // Debugging log
        setTokenList(tokens);
      } catch (error) {
        console.error('Failed to fetch token list:', error);
      }
    };
    fetchList();
  }, [selectedChain]);

  // Fetch pools data (from TokenListComponent's API)
  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response = await axios.get('https://dohcloud.azurewebsites.net/api_token_transactions'); // Replace with actual pools API URL
        setPools(response.data);
        console.log("Pools data fetched:", response.data); // Debugging log
      } catch (error) {
        console.error('Error fetching pools data:', error);
      }
    };
    fetchPools();
  }, []);

  // Fetch token pairs using react-query
  const { data, error: queryError, isLoading, refetch } = useQuery({
    queryKey: ['tokenPairs', selectedChain],
    queryFn: () => fetchTokenPairs(selectedChain, apiKey),
    onSuccess: (data) => {
      console.log("Token pairs fetched:", data); // Debugging log
      const prices = {};

      data.forEach((pair) => {
        // Assuming 'pair.price' is the price of the base token in USD
        if (pair.token0.symbol === chains[selectedChain].baseTokenSymbol) {
          prices[pair.token1.symbol] = pair.price;
        } else {
          // If token1 is a stablecoin like USDT or USDC, set its price to 1
          if (['USDT', 'USDC', 'DAI', 'BUSD'].includes(pair.token1.symbol)) {
            prices[pair.token1.symbol] = 1;
          } else {
            // For other tokens, you might need to fetch their prices separately
            prices[pair.token1.symbol] = pair.price; // Ensure this is correct based on API
          }
        }
      });

      setTokenPrices(prices);
      console.log("Token prices set:", prices); // Debugging log
    },
    enabled: !!selectedChain && !!apiKey,
    refetchInterval: 60000, // Refetch every 60 seconds
  });

  // Fetch votes for all tokens using useQueries
  const voteQueries = useQueries({
    queries: (data || []).map((pair) => ({
      queryKey: ['communitySentiment', pair.token1.address],
      queryFn: async () => {
        try {
          const response = await axios.get(`https://dohprod.azurewebsites.net/communitysentiment`, {
            params: { tokenId: pair.token1.address },
          });
          return { ...response.data, address: pair.token1.address }; // Include address in result
        } catch (error) {
          console.error(`Error fetching votes for ${pair.token1.symbol}:`, error);
          return { bullishVotes: 0, bearishVotes: 0, address: pair.token1.address };
        }
      },
      enabled: !!pair.token1.address,
    })),
  });

  // Use a reducer to map vote data to token addresses
  useEffect(() => {
    const newVotesMap = voteQueries.reduce((acc, query) => {
      const { address, bullishVotes = 0, bearishVotes = 0 } = query.data || {};
      if (address) {
        acc[address] = { bullishVotes, bearishVotes };
      }
      return acc;
    }, {});

    setVotesMap(newVotesMap);
  }, [voteQueries]);

  // Display pairs with votes included and sorted accordingly
  const displayPairs = useMemo(() => {
    if (!data) return [];

    // Map data to include votes
    const pairsWithVotes = data
      .filter((pair) => pair.token1.symbol.toLowerCase() !== 'swap')
      .filter((pair) => {
        if (searchQuery) {
          const tokenSymbol = pair.token1.symbol?.toLowerCase() || '';
          const tokenName = pair.token1.name?.toLowerCase() || '';
          const pairAddress = pair.pairAddress?.toLowerCase() || '';
          return (
            tokenSymbol.includes(searchQuery.toLowerCase()) ||
            tokenName.includes(searchQuery.toLowerCase()) ||
            pairAddress.includes(searchQuery.toLowerCase())
          );
        }
        // Additional filters if needed
        if (filters.hideZeroVolume && pair.volume24h === 0) return false;
        if (filters.hideSub500Liquidity && pair.liquidity < 500) return false;
        if (filters.hideNonAlphTokens && nonAlphTokens.includes(pair.token1.symbol)) return false;

        return true;
      })
      .map((pair) => ({
        ...pair,
        votes: votesMap[pair.token1.address] || { bullishVotes: 0, bearishVotes: 0 },
      }));

    // Sort based on orderBy and order
    const sortedPairs = pairsWithVotes.sort((a, b) => {
      let aValue, bValue;

      if (orderBy.includes('.')) {
        const [parent, child] = orderBy.split('.');
        aValue = a[parent]?.[child] ?? '';
        bValue = b[parent]?.[child] ?? '';
      } else if (orderBy === 'votes') {
        // Sum of bullish and bearish votes for sorting by votes
        aValue = (a.votes.bullishVotes || 0) + (a.votes.bearishVotes || 0);
        bValue = (b.votes.bullishVotes || 0) + (b.votes.bearishVotes || 0);
      } else {
        aValue = a[orderBy] ?? '';
        bValue = b[orderBy] ?? '';
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      return 0;
    });

    return sortedPairs;
  }, [data, orderBy, order, searchQuery, filters, votesMap]);

  // Updated enhancedPairs with improved matching logic and consistent data types
  const enhancedPairs = useMemo(() => {
    return displayPairs.map(pair => {
      // Find matching pool by token1 address
      const matchingPool = pools.find(pool => pool.token1?.address === pair.token1?.address);

      if (matchingPool) {
        console.log(`Found matching pool for ${pair.token1.symbol} (${pair.token1.address}):`, matchingPool);
      } else {
        console.log(`No matching pool found for ${pair.token1.symbol} (${pair.token1.address})`);
      }

      return {
        ...pair,
        exToken1Price: matchingPool?.token1?.onchainPrice ? Number(matchingPool.token1.onchainPrice) : null,
        exToken0Price: matchingPool?.token0?.onchainPrice ? Number(matchingPool.token0.onchainPrice) : null,
        exVolume: matchingPool?.volume !== undefined ? Number(matchingPool.volume) : null,
        exTvl: matchingPool?.tvl !== undefined ? Number(matchingPool.tvl) : null,
        exDex: matchingPool?.dex || 'EX DEX', // Assuming 'dex' field exists; default to 'EX DEX'
      };
    });
  }, [displayPairs, pools]);

  // Handle sorting
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle table row click to open TokenDetails
  const handleTableRowClick = (pair) => {
    if (selectedToken === pair.token1.symbol) {
      setSelectedToken(null);
      setSelectedPairAddress(null);
      setIsTokenDetailsModalOpen(false);
    } else {
      setSelectedToken(pair.token1.symbol);
      setSelectedPairAddress(pair.pairAddress);
      setIsTokenDetailsModalOpen(true);
    }
  };

  // Handle bubble click to open TokenDetails
  const handleBubbleClick = (pair) => {
    setSelectedPairAddress(pair.pairAddress);
    setSelectedToken(pair.token1.symbol);
    setIsTokenDetailsModalOpen(true);
  };

  // Callback to handle votes update from TokenDetails/DOHvote
  const handleVotesUpdate = useCallback((tokenAddress, newBullishVotes, newBearishVotes) => {
    setVotesMap((prevVotesMap) => ({
      ...prevVotesMap,
      [tokenAddress]: {
        bullishVotes: newBullishVotes,
        bearishVotes: newBearishVotes,
      },
    }));
  }, []);

  // Track rank movements
  useEffect(() => {
    const newRankMovements = {};
    const newPreviousRanks = { ...previousRanks };

    enhancedPairs.forEach((pair, index) => { // Changed from displayPairs to enhancedPairs
      const symbol = pair.token1.symbol;
      const previousRank = previousRanks[symbol];
      const currentRank = index + 1;

      if (previousRank) {
        if (currentRank < previousRank) {
          newRankMovements[symbol] = { direction: 'up', amount: previousRank - currentRank };
        } else if (currentRank > previousRank) {
          newRankMovements[symbol] = { direction: 'down', amount: currentRank - previousRank };
        } else {
          newRankMovements[symbol] = { direction: 'no-change', amount: 0 };
        }
      } else {
        newRankMovements[symbol] = { direction: 'no-change', amount: 0 };
      }

      // Update the current rank for the symbol
      newPreviousRanks[symbol] = currentRank;
    });

    setRankMovements(newRankMovements);
    setPreviousRanks(newPreviousRanks);
  }, [enhancedPairs, previousRanks]); // Updated dependency from displayPairs to enhancedPairs

  // Calculate highest metrics for bars
  const highestMarketCap = useMemo(() => {
    return enhancedPairs.reduce((max, pair) => Math.max(max, pair.marketCap > 0 ? pair.marketCap : 0), 0);
  }, [enhancedPairs]);

  const highestVolume = useMemo(() => {
    return enhancedPairs.reduce((max, pair) => Math.max(max, pair.volume24h + (pair.exVolume || 0)), 1);
  }, [enhancedPairs]);

  const highestLiquidity = useMemo(() => {
    if (!enhancedPairs || enhancedPairs.length === 0) return 1;
    return Math.max(...enhancedPairs.map((pair) => pair.liquidity + (pair.exTvl || 0)));
  }, [enhancedPairs]);

  const marketCapPercentages = useMemo(() => {
    return enhancedPairs.map((pair) => ({
      ...pair,
      marketCapPercentage: highestMarketCap > 0 ? (pair.marketCap / highestMarketCap) * 100 : 0,
    }));
  }, [enhancedPairs, highestMarketCap]);

  // Identify the top 3 tokens by 24-hour percentage gain
  const topGainers = useMemo(() => {
    return enhancedPairs
      .slice() // Copy array to avoid mutation
      .sort((a, b) => (b.price_change_24h || 0) - (a.price_change_24h || 0))
      .slice(0, 3)
      .map(pair => pair.token1.symbol);
  }, [enhancedPairs]);

  const averages = useMemo(() => {
    const calculateAverages = (tokens) => {
      const metrics = tokens.reduce(
        (acc, token) => {
          const liquidityToMarketCapRatio = token.marketCap > 0 ? token.liquidity / token.marketCap : 0;
          const volumeToLiquidityRatio = token.liquidity > 0 ? (token.volume24h + (token.exVolume || 0)) / (token.liquidity + (token.exTvl || 0)) : 0;
          const slippageTolerance = token.liquidity > 0 ? ((token.liquidity + (token.exTvl || 0) - (token.volume24h + (token.exVolume || 0))) / (token.liquidity + (token.exTvl || 0))) * 100 : 0;

          if (token.marketCap > 0 && (token.liquidity + (token.exTvl || 0)) > 0) {
            acc.liquidityToMarketCap += liquidityToMarketCapRatio;
            acc.volumeToLiquidity += volumeToLiquidityRatio;
            acc.slippageTolerance += slippageTolerance;
            acc.count += 1;
          }

          return acc;
        },
        { liquidityToMarketCap: 0, volumeToLiquidity: 0, slippageTolerance: 0, count: 0 }
      );

      return {
        liquidityToMarketCap: metrics.count > 0 ? (metrics.liquidityToMarketCap / metrics.count) * 100 : 0,
        volumeToLiquidity: metrics.count > 0 ? (metrics.volumeToLiquidity / metrics.count) : 0,
        slippageTolerance: metrics.count > 0 ? (metrics.slippageTolerance / metrics.count) : 0,
      };
    };

    return calculateAverages(marketCapPercentages);
  }, [marketCapPercentages]);

  // Function to get logo URI
  const getLogoURI = (symbol, tokenList = []) => {
    if (!Array.isArray(tokenList)) {
      console.error('getLogoURI: tokenList is not an array:', tokenList);
      return defaultLogoURL;
    }

    // Special handling for known symbols
    if (symbol === 'BTC') {
      return 'https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=010';
    } else if (symbol === 'ETH') {
      return 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=010';
    } else if (symbol === 'ANS') {
      return 'https://raw.githubusercontent.com/alephium/token-list/master/logos/ANSd.png';
    }

    const token = tokenList.find((token) => token.symbol === symbol);
    if (!token) {
      console.log(`No logo found for ${symbol}, using default.`);
      return defaultLogoURL;
    }

    return token.image || token.logoURI || defaultLogoURL;
  };

  const boundGetLogoURI = useCallback((symbol) => getLogoURI(symbol, tokenList), [tokenList]);

  // Open/Close Modals
  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const openTokenDetailsModal = () => setIsTokenDetailsModalOpen(true);
  const closeTokenDetailsModal = () => {
    setIsTokenDetailsModalOpen(false);
    setSelectedPairAddress(null);
    setSelectedToken(null);
  };

  // Handle clicks outside the filter dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Prepare options for react-select
  const selectOptions = useMemo(() => {
    return Object.keys(chains).map((chainName) => ({
      value: chainName,
      label: chainName,
      logo: chains[chainName].logo,
    }));
  }, []);

  return (
    <div className="tokens-page-container">
      <h1 className="page-title">Decentralized. Open. Hub.</h1>

      <TokenTicker />

      {/* Buttons Toolbar */}
      <div className="buttons-toolbar main-toolbar">
        {/* Instant Feedback Button */}
        <button
          className="feedback-button"
          onClick={(e) => {
            e.stopPropagation();
            openFeedbackModal();
          }}
        >
          Instant Feedback
        </button>

        {/* Chain Dropdown Next to Instant Feedback */}
        <div className="chain-dropdown-container">
          <Select
            options={selectOptions}
            value={selectOptions.find((option) => option.value === selectedChain)}
            onChange={(selectedOption) => {
              console.log("Selected chain:", selectedOption.value); // Debugging log
              setSelectedChain(selectedOption.value);
            }}
            className="chain-dropdown"
            classNamePrefix="custom-select"
            components={{ Option: OptionComponent, SingleValue: SingleValueComponent }}
            styles={{
              control: (provided) => ({
                ...provided,
                minWidth: 150,
                width: '100%',
                backgroundColor: '#000000', // Black background
                border: '1px solid #FFD700', // Optional border styling
                boxShadow: 'none',
                cursor: 'pointer',
              }),
              singleValue: (provided) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
                color: '#FFD700', // Yellow font for selected value
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#FFD700', // Yellow font for placeholder
              }),
              input: (provided) => ({
                ...provided,
                color: '#FFD700', // Yellow font for input text
              }),
              option: (provided, state) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: state.isFocused ? '#333333' : '#000000', // Dark gray on focus, black otherwise
                color: '#FFD700', // Yellow font for options
                cursor: 'pointer',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#000000', // Black background for menu
                color: '#FFD700', // Yellow font for menu items
                zIndex: 9999, // Ensure dropdown appears above other elements
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: '#FFD700', // Yellow color for dropdown arrow
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: '#FFD700', // Yellow color for separator
              }),
            }}
            placeholder="Select Chain"
          />
        </div>

        {/* Filter Button */}
        <button
          className="filter-icon"
          onClick={() => setShowFilters((prev) => !prev)}
          title="Filter Options"
        >
          <FaFilter />
        </button>

        {/* View Toggle Buttons */}
        <button
          className={`view-toggle-btn ${viewMode === 'table' ? 'active' : ''}`}
          onClick={() => setViewMode('table')}
          title="Table View"
        >
          <FaTable />
        </button>
        <button
          className={`view-toggle-btn ${viewMode === 'bubbles' ? 'active' : ''}`}
          onClick={() => setViewMode('bubbles')}
          title="Bubbles View"
        >
          <FaTh />
        </button>
      </div>

      {showFilters && (
        <div className="filter-dropdown" ref={filterRef}>
          <Filter filters={filters} setFilters={setFilters} />
        </div>
      )}

      {isLoading && <div className="loading-message">Loading {selectedChain} Tokens...</div>}
      {queryError && <div className="error-message">Error: {queryError.message}</div>}

      {!isLoading && !queryError && tokenList.length > 0 && (
        <>
          {viewMode === 'table' ? (
            <div className="table-wrapper">
              <table className="shared-table table">
                <thead>
                  <tr>
                    {/* Token Column */}
                    <th onClick={() => handleSortRequest('token1.symbol')} style={{ cursor: 'pointer' }}>
                      Token {orderBy === 'token1.symbol' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* Votes Column */}
                    <th onClick={() => handleSortRequest('votes')} style={{ cursor: 'pointer' }}>
                      Votes {orderBy === 'votes' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* Price Column */}
                    <th onClick={() => handleSortRequest('price')} style={{ cursor: 'pointer' }}>
                      Price {orderBy === 'price' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* 24h Change */}
                    <th onClick={() => handleSortRequest('price_change_24h')} style={{ cursor: 'pointer' }}>
                      24h {orderBy === 'price_change_24h' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* ALPH Ratio */}
                    <th onClick={() => handleSortRequest('baseTokenRatio')} style={{ cursor: 'pointer' }}>
                      {chains[selectedChain].baseTokenSymbol} Ratio {orderBy === 'baseTokenRatio' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* 5min Change */}
                    <th onClick={() => handleSortRequest('price_change_5min')} style={{ cursor: 'pointer' }}>
                      5min {orderBy === 'price_change_5min' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* 1h Change */}
                    <th onClick={() => handleSortRequest('price_change_1h')} style={{ cursor: 'pointer' }}>
                      1h {orderBy === 'price_change_1h' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* 4h Change */}
                    <th onClick={() => handleSortRequest('price_change_4h')} style={{ cursor: 'pointer' }}>
                      4h {orderBy === 'price_change_4h' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* Volume with Bar */}
                    <th onClick={() => handleSortRequest('volume24h')} style={{ cursor: 'pointer' }}>
                      Volume {orderBy === 'volume24h' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* Liquidity with Bar */}
                    <th onClick={() => handleSortRequest('liquidity')} style={{ cursor: 'pointer' }}>
                      Liquidity {orderBy === 'liquidity' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* Market Cap with Bar */}
                    <th onClick={() => handleSortRequest('marketCap')} style={{ cursor: 'pointer' }}>
                      Market Cap {orderBy === 'marketCap' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    {/* Total Supply */}
                    <th onClick={() => handleSortRequest('totalSupply')} style={{ cursor: 'pointer' }}>
                      Total Supply {orderBy === 'totalSupply' ? (order === 'asc' ? '↑' : '↓') : ''}
                    </th>
         
                  </tr>
                </thead>
                <tbody>
                  {enhancedPairs.map((pair, index) => {
                    // Calculate total Volume and Liquidity
                    const totalVolume = pair.volume24h + (pair.exVolume || 0);
                    const totalLiquidity = pair.liquidity + (pair.exTvl || 0);

                    // Prepare tooltip content with DEX names
                    const volumeTitle = `Ayin: ${formatLargeNumber(pair.volume24h)}\nEx: ${formatLargeNumber(pair.exVolume || 0)}`;
                    const liquidityTitle = `Ayin: ${formatLargeNumber(pair.liquidity)}\nEx: ${formatLargeNumber(pair.exTvl || 0)}`;

                    return (
                      <React.Fragment key={pair.pairAddress}>
                        <tr
                          onClick={() => handleTableRowClick(pair)}
                          className={`${selectedToken === pair.token1.symbol ? 'selected' : ''} ${topGainers.includes(pair.token1.symbol) ? 'top-gainer' : ''}`}
                        >
                          <td className="token-symbol">
                            {/* Rank and Movement Arrows */}
                            <span className="token-rank">
                              #{index + 1}
                              {rankMovements[pair.token1.symbol]?.direction === 'up' && (
                                <FaArrowUp
                                  color="green"
                                  title={`Moved up ${rankMovements[pair.token1.symbol].amount} spot(s)`}
                                  style={{ marginLeft: '4px' }}
                                />
                              )}
                              {rankMovements[pair.token1.symbol]?.direction === 'down' && (
                                <FaArrowDown
                                  color="red"
                                  title={`Moved down ${rankMovements[pair.token1.symbol].amount} spot(s)`}
                                  style={{ marginLeft: '4px' }}
                                />
                              )}
                            </span>

                            {/* Favorite Button */}
                            <button
                              className={`favorite-btn ${favorites.includes(pair.token1.symbol) ? 'favorited' : ''}`}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering row click
                                toggleFavorite(pair.token1.symbol);
                              }}
                              aria-label={`Favorite ${pair.token1.symbol}`}
                            >
                              {favorites.includes(pair.token1.symbol) ? '★' : '☆'}
                            </button>

                            {/* Token Logo */}
                            <img
                              src={pair.token1.symbol === 'USDT' || pair.token1.symbol === 'USDC' ? alphLogo : boundGetLogoURI(pair.token1.symbol)}
                              alt={pair.token1.symbol}
                              className="token-logo"
                              onError={(e) => {
                                if (!e.target.hasError) {
                                  e.target.src = defaultLogoURL;
                                  console.log(`No logo found for ${pair.token1.symbol}, using default.`);
                                  e.target.hasError = true;  // Flag to prevent further triggers
                                }
                              }}
                            />

                            {/* Token Name with Abbreviation */}
                            <span
  className="token-name"
  title={pair.token1.symbol}  // Title directly uses the symbol
>
  {abbreviateName(pair.token1.symbol, 10)}  
</span>

                          </td>

                          {/* Votes Cell */}
                          <td className="cell-number votes-cell">
                            {pair.votes.bullishVotes + pair.votes.bearishVotes}
                          </td>

                          {/* Price Cell */}
                          <td className="cell-number price-cell">
                            {pair.exToken1Price !== null ? (
                              <span style={{ color: '#fff',fontWeight: 'bold'}}> {/* Changed color to yellow */}
                                ${pair.exToken1Price.toFixed(4)}
                              </span>
                            ) : (
                              pair.price !== 0 ? formatPrice(pair.price) : 'N/A'
                            )}
                          </td>

                          {/* 24h Change */}
                          <td
                            className={`cell-number ${
                              pair.price_change_24h === 0
                                ? 'zero-change'
                                : pair.price_change_24h > 0
                                ? 'positive-change'
                                : 'negative-change'
                            }`}
                          >
                            {pair.price_change_24h?.toFixed(2) || 'N/A'}%
                          </td>

                          {/* ALPH Ratio */}
                          <td className="cell-number">
                            {pair.baseTokenRatio > 0 ? formatRatio(pair.baseTokenRatio) : 'N/A'}
                          </td>

                          {/* 5min Change */}
                          <td
                            className={`cell-number ${
                              pair.price_change_5min === 0
                                ? 'zero-change'
                                : pair.price_change_5min > 0
                                ? 'positive-change'
                                : 'negative-change'
                            }`}
                          >
                            {pair.price_change_5min?.toFixed(2) || 'N/A'}%
                          </td>

                          {/* 1h Change */}
                          <td
                            className={`cell-number ${
                              pair.price_change_1h === 0
                                ? 'zero-change'
                                : pair.price_change_1h > 0
                                ? 'positive-change'
                                : 'negative-change'
                            }`}
                          >
                            {pair.price_change_1h?.toFixed(2) || 'N/A'}%
                          </td>

                          {/* 4h Change */}
                          <td
                            className={`cell-number ${
                              pair.price_change_4h === 0
                                ? 'zero-change'
                                : pair.price_change_4h > 0
                                ? 'positive-change'
                                : 'negative-change'
                            }`}
                          >
                            {pair.price_change_4h?.toFixed(2) || 'N/A'}%
                          </td>

                          {/* Volume with Stacked Bar */}
                          <td className="cell-number">
                            <div className="volume-bar" title={volumeTitle}>
                              <div
                                className="volume-main"
                                style={{ width: `${totalVolume > 0 ? (pair.volume24h / totalVolume) * 100 : 0}%` }}
                              ></div>
                              <div
                                className="volume-ex"
                                style={{ width: `${pair.exVolume ? (pair.exVolume / totalVolume) * 100 : 0}%` }}
                              ></div>
                            </div>
                            <span className="volume-text">${formatLargeNumber(totalVolume)}</span>
                          </td>

                          {/* Liquidity with Stacked Bar */}
                          <td className="cell-number">
                            <div className="liquidity-bar" title={liquidityTitle}>
                              <div
                                className="liquidity-main"
                                style={{ width: `${totalLiquidity > 0 ? (pair.liquidity / totalLiquidity) * 100 : 0}%` }}
                              ></div>
                              <div
                                className="liquidity-ex"
                                style={{ width: `${pair.exTvl ? (pair.exTvl / totalLiquidity) * 100 : 0}%` }}
                              ></div>
                            </div>
                            <span className="liquidity-text">${formatLargeNumber(totalLiquidity)}</span>
                          </td>

                          {/* Market Cap with Bar */}
                   {/* Market Cap */}
<td className="cell-number">
  {pair.marketCap && !isNaN(pair.marketCap) ? `$${formatLargeNumber(pair.marketCap)}` : 'N/A'}
</td>


                          {/* Total Supply */}
                          <td className="cell-number">{formatLargeNumber(pair.totalSupply)}</td>
                        </tr>

                        {/* Expanded Row for Token Details and Metrics */}
                        {selectedToken === pair.token1.symbol && (
                          <tr className="expanded-info-row">
                            <td colSpan="14"> {/* Adjusted colspan to match the number of columns (14) */}
                              <div className="token-details-and-metrics">
                                <TokenDetails
                                  selectedPairAddress={pair.pairAddress}
                                  token1Address={pair.token1.address}
                                  token0={pair.token0}
                                  walletAddress={null}
                                  votes={pair.votes} // Now passing an object
                                  tokenList={tokenList}
                                  tokenPrices={tokenPrices}
                                  onVotesUpdate={handleVotesUpdate} // Pass the address directly
                                />
                                <LiquidityMetrics pair={pair} averages={averages} />
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <TokenBubbles
              tokens={enhancedPairs} // Use enhancedPairs instead of displayPairs
              getLogoURI={boundGetLogoURI}
              handleRowClick={handleBubbleClick}
              favorites={favorites} // Existing props
              toggleFavorite={toggleFavorite} // Existing props
            />
          )}

          {/* Feedback Modal */}
          <FeedbackModal isOpen={isFeedbackModalOpen} onClose={closeFeedbackModal} />

          {/* Token Details Modal for Bubbles View */}
          {viewMode === 'bubbles' && selectedPairAddress && (
            <Modal
              isOpen={isTokenDetailsModalOpen}
              onRequestClose={closeTokenDetailsModal}
              contentLabel="Token Details"
              className="token-details-modal"
              overlayClassName="token-details-overlay"
              shouldCloseOnOverlayClick={true}
            >
              {(() => {
                const selectedPair = enhancedPairs.find((pair) => pair.pairAddress === selectedPairAddress);
                if (!selectedPair) return <p>Loading...</p>;

                return (
                  <>
                    <TokenDetails
                      selectedPairAddress={selectedPair.pairAddress}
                      token1Address={selectedPair.token1.address}
                      token0={selectedPair.token0}
                      walletAddress={null}
                      votes={selectedPair.votes} // Passing vote object
                      tokenList={tokenList} // Ensure tokenList is passed
                      tokenPrices={tokenPrices} // Ensure tokenPrices is passed
                      onVotesUpdate={handleVotesUpdate} // Pass the callback from parent
                    />
                    <LiquidityMetrics pair={selectedPair} averages={averages} />
                    <button onClick={closeTokenDetailsModal} className="close-modal-button">
                      Close
                    </button>
                  </>
                );
              })()}
            </Modal>
          )}

          {/* Handle case when tokens are not found */}
          {!isLoading && !queryError && tokenList.length === 0 && (
            <div className="no-tokens-found">
              <p>No tokens found.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

// PropTypes for Tokens Component
Tokens.propTypes = {
  searchQuery: PropTypes.string,
};

// Custom Option Component for react-select (if needed)
const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 200,
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
};

export default Tokens;
