/* src/components/TokenBubbles/TokenBubbles.jsx */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import './tokenbubbles.css';
import { Tooltip } from 'react-tooltip';
import Modal from 'react-modal';
import throttle from 'lodash.throttle';

const alphLogo = '/assets/logos/ALPH.png'; // Ensure correct logo path

// Ensure Modal is attached to the app element for accessibility
Modal.setAppElement('#root');

const defaultLogoURL = 'https://via.placeholder.com/150?text=No+Logo';

/* Constants for physics simulation */
const FRAME_RATE = 60;
const TIME_STEP = 1 / FRAME_RATE;
const MAX_SPEED = 0.02; // Further reduced speed for smoother movement
const BOUNCE_DAMPING = 0.9; // Velocity reduction upon collision
const BLOW_FORCE = 0.005; // Further reduced force for gentle pushing from the edges
const FRICTION = 0.997; // Increased friction to slow down bubbles more gradually

const TokenBubbles = ({ tokens = [], getLogoURI, handleRowClick }) => {
  const containerRef = useRef(null);
  const bubblesRef = useRef([]); // Array of bubble data { id, x, y, vx, vy, size, pair }
  const animationFrameRef = useRef(null);

  const [bubbles, setBubbles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  // Throttle state updates to approximately 60fps
  const throttledSetBubbles = useCallback(
    throttle((updatedBubbles) => {
      setBubbles([...updatedBubbles]);
    }, 16), // ~60fps
    []
  );

  // Function to calculate bubble size based on volume
  const getBubbleSize = useCallback(
    (volume) => {
      const minSize = 100; // Increased minimum size to match CSS
      const maxSize = 200; // Increased maximum size to match CSS
      const volumes = tokens.map((token) => token.volume24h);
      const minVolume = Math.min(...volumes);
      const maxVolume = Math.max(...volumes);
      const normalizedVolume = (volume - minVolume) / (maxVolume - minVolume || 1); // Avoid division by zero
      const size = minSize + normalizedVolume * (maxSize - minSize);
      return size;
    },
    [tokens]
  );

  // Initialize bubbles with positions and velocities
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const centerX = containerWidth / 2;
    const centerY = containerHeight / 2;

    // Initialize bubble data
    const initialBubbles = [];
    tokens.forEach((pair, index) => {
      const size = getBubbleSize(pair.volume24h);
      let x, y;
      let attempts = 0;
      const maxAttempts = 100;
      let overlapping = false;

      do {
        overlapping = false;

        // Push larger bubbles towards the center, and allow bubbles to occupy the entire viewport
        const biasFactor = 1 - size / 200; // Larger bubbles have a bias towards the center
        const randomOffsetX = (Math.random() - 0.5) * containerWidth * biasFactor;
        const randomOffsetY = (Math.random() - 0.5) * containerHeight * biasFactor;

        x = centerX + randomOffsetX - size / 2;
        y = centerY + randomOffsetY - size / 2;

        // Ensure bubbles stay within the container
        x = Math.max(0, Math.min(x, containerWidth - size));
        y = Math.max(0, Math.min(y, containerHeight - size));

        // Check for overlap with existing bubbles
        for (let i = 0; i < initialBubbles.length; i++) {
          const other = initialBubbles[i];
          const dx = x + size / 2 - (other.x + other.size / 2);
          const dy = y + size / 2 - (other.y + other.size / 2);
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < (size / 2 + other.size / 2 + 30)) { // Increased padding to 30px for larger bubbles
            overlapping = true;
            break;
          }
        }

        attempts++;
        if (attempts > maxAttempts) {
          console.warn(
            `Could not place bubble for token ${pair.token1.symbol} after ${maxAttempts} attempts.`
          );
          break; // Prevent infinite loop
        }
      } while (overlapping && attempts < maxAttempts);

      // Random initial velocities
      const angle = Math.random() * 2 * Math.PI;
      const speed = Math.random() * MAX_SPEED; // Slower speed
      const vx = Math.cos(angle) * speed;
      const vy = Math.sin(angle) * speed;

      initialBubbles.push({
        id: index,
        x: x,
        y: y,
        vx: vx,
        vy: vy,
        size: size,
        pair: pair,
      });
    });

    bubblesRef.current = initialBubbles;
    throttledSetBubbles(initialBubbles); // Trigger initial render

    // Start animation loop
    const animate = () => {
      updateBubbles();
      animationFrameRef.current = requestAnimationFrame(animate);
    };
    animate();

    // Cleanup on unmount
    return () => {
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [tokens, getBubbleSize, throttledSetBubbles]);

  // Function to update bubble positions and handle collisions
  const updateBubbles = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const updatedBubbles = bubblesRef.current.map((b) => ({ ...b }));

    // Update positions
    updatedBubbles.forEach((b) => {
      b.x += b.vx;
      b.y += b.vy;

      // Apply "blow" force if bubbles are too close to the edges
      if (b.x < containerWidth * 0.1) {
        b.vx += BLOW_FORCE; // Push bubble to the right
      } else if (b.x + b.size > containerWidth * 0.9) {
        b.vx -= BLOW_FORCE; // Push bubble to the left
      }

      if (b.y < containerHeight * 0.1) {
        b.vy += BLOW_FORCE; // Push bubble down
      } else if (b.y + b.size > containerHeight * 0.9) {
        b.vy -= BLOW_FORCE; // Push bubble up
      }

      // Wall collision
      if (b.x <= 0) {
        b.x = 0;
        b.vx = Math.abs(b.vx) * BOUNCE_DAMPING;
      } else if (b.x + b.size >= containerWidth) {
        b.x = containerWidth - b.size;
        b.vx = -Math.abs(b.vx) * BOUNCE_DAMPING;
      }

      if (b.y <= 0) {
        b.y = 0;
        b.vy = Math.abs(b.vy) * BOUNCE_DAMPING;
      } else if (b.y + b.size >= containerHeight) {
        b.y = containerHeight - b.size;
        b.vy = -Math.abs(b.vy) * BOUNCE_DAMPING;
      }
    });

    // Handle bubble collisions
    for (let i = 0; i < updatedBubbles.length; i++) {
      for (let j = i + 1; j < updatedBubbles.length; j++) {
        const b1 = updatedBubbles[i];
        const b2 = updatedBubbles[j];

        const dx = b2.x + b2.size / 2 - (b1.x + b1.size / 2);
        const dy = b2.y + b2.size / 2 - (b1.y + b1.size / 2);
        const distance = Math.sqrt(dx * dx + dy * dy);
        const minDist = (b1.size + b2.size) / 2 + 30; // Increased padding to 30px

        if (distance < minDist && distance > 0) {
          // Calculate overlap
          const overlap = minDist - distance;

          // Normalize the vector
          const nx = dx / distance;
          const ny = dy / distance;

          // Displace bubbles to prevent overlap
          b1.x -= nx * overlap / 2;
          b1.y -= ny * overlap / 2;
          b2.x += nx * overlap / 2;
          b2.y += ny * overlap / 2;

          // Calculate relative velocity
          const dvx = b1.vx - b2.vx;
          const dvy = b1.vy - b2.vy;

          // Calculate velocity along the normal
          const vn = dvx * nx + dvy * ny;

          if (vn > 0) continue; // They are moving away from each other

          // Calculate impulse
          const impulse = -(1 + BOUNCE_DAMPING) * vn / 2; // Assuming equal mass

          // Apply impulse to the velocities
          b1.vx += impulse * nx;
          b1.vy += impulse * ny;
          b2.vx -= impulse * nx;
          b2.vy -= impulse * ny;
        }
      }
    }

    // Apply friction, limit speed, and introduce slight random direction changes for natural floating
    updatedBubbles.forEach((b) => {
      // Apply friction
      b.vx *= FRICTION;
      b.vy *= FRICTION;

      // Limit speed to prevent excessive movement
      const speed = Math.sqrt(b.vx * b.vx + b.vy * b.vy);
      if (speed > MAX_SPEED) {
        b.vx = (b.vx / speed) * MAX_SPEED;
        b.vy = (b.vy / speed) * MAX_SPEED;
      }

      // Introduce slight random direction change for natural floating
      const directionChange = (Math.random() - 0.5) * 0.002; // Small angle change
      const angle = Math.atan2(b.vy, b.vx) + directionChange;
      const newVx = Math.cos(angle) * speed;
      const newVy = Math.sin(angle) * speed;
      b.vx = newVx;
      b.vy = newVy;
    });

    // Update the ref
    bubblesRef.current = updatedBubbles;

    // Update state using throttled function
    throttledSetBubbles(updatedBubbles);
  }, [throttledSetBubbles]);

  // Format price for display (No longer needed since we're removing price)
  // Removed formatPrice function

  // Helper function to format volume
  const formatVolume = (volume) => {
    if (volume >= 1e12) return `${(volume / 1e12).toFixed(2)}T`;
    if (volume >= 1e9) return `${(volume / 1e9).toFixed(2)}B`;
    if (volume >= 1e6) return `${(volume / 1e6).toFixed(2)}M`;
    if (volume >= 1e3) return `${(volume / 1e3).toFixed(2)}K`;
    return volume;
  };

  // Handle bubble click to open modal
  const openModal = (token) => {
    setSelectedToken(token);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedToken(null);
  };

  return (
    <>
      <div className="bubbles-container" ref={containerRef}>
        {bubbles.map((b) => {
          const isUp = b.pair.price_change_24h > 0;
          const borderGradient = isUp
            ? 'linear-gradient(135deg, var(--gradient-positive-start), var(--gradient-positive-end))'
            : 'linear-gradient(135deg, var(--gradient-negative-start), var(--gradient-negative-end))';
          const indicator = isUp ? (
            <svg
              width="16" /* Increased size */
              height="16" /* Increased size */
              viewBox="0 0 20 20"
              fill="#00FF7F"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
            >
              <path d="M10 0L12.09 6.26H18.18L13.45 9.51L15.54 15.77L10 12.52L4.46 15.77L6.55 9.51L1.82 6.26H7.91L10 0Z" />
            </svg>
          ) : (
            <svg
              width="16" /* Increased size */
              height="16" /* Increased size */
              viewBox="0 0 20 20"
              fill="#FF6347"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
            >
              <path d="M10 20L7.91 13.74H1.82L6.55 10.49L4.46 4.23L10 7.48L15.54 4.23L13.45 10.49L18.18 13.74H12.09L10 20Z" />
            </svg>
          );

          return (
            <div
              key={b.id}
              className={`bubble ${isUp ? 'bubble--positive' : 'bubble--negative'}`}
              onClick={() => {
                handleRowClick(b.pair);
                openModal(b.pair);
              }}
              style={{
                width: `${b.size}px`,
                height: `${b.size}px`,
                top: `${b.y}px`,
                left: `${b.x}px`,
              }}
              role="button"
              aria-label={`Token ${b.pair.token1.symbol}. 24-hour change: ${b.pair.price_change_24h?.toFixed(2) || 'N/A'} percent.`}
              tabIndex="0"
              data-tip={`24h Change: ${b.pair.price_change_24h?.toFixed(2) || 'N/A'}%`}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleRowClick(b.pair);
                  openModal(b.pair);
                }
              }}
            >
              <div className="bubble__border" style={{ background: borderGradient }}>
                <div className="bubble__content">
                  <img
                    src={
                      ['USDT', 'USDC'].includes(b.pair.token1.symbol)
                        ? alphLogo
                        : getLogoURI(b.pair.token1.symbol)
                    }
                    alt={`${b.pair.token1.symbol} logo`}
                    className="bubble__logo"
                    loading="lazy"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultLogoURL;
                    }}
                  />
                  <div className="bubble__info">
                    <h3 className="bubble__info-title">{b.pair.token1.symbol}</h3>
                    {/* Removed the price display */}
                    <div className={`bubble__price-change ${isUp ? 'bubble__price-change--positive' : 'bubble__price-change--negative'}`}>
                      {indicator} {b.pair.price_change_24h?.toFixed(2) || 'N/A'}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Tooltip effect="solid" multiline={true} />

      {/* Modal for Token Details */}
      {selectedToken && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="token-modal"
          overlayClassName="modal-overlay"
          contentLabel={`${selectedToken.token1.symbol} Details`}
        >
          <h2>{selectedToken.token1.symbol} Details</h2>
          {/* Add more detailed information about the token here */}
          <p><strong>24h Change:</strong> {selectedToken.price_change_24h?.toFixed(2) || 'N/A'}%</p>
          <p><strong>24h Volume:</strong> {formatVolume(selectedToken.volume24h)}</p>
          {/* Add additional fields as necessary */}
          <button onClick={closeModal}>Close</button>
        </Modal>
      )}
    </>
  );
};

TokenBubbles.propTypes = {
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      token1: PropTypes.shape({
        symbol: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        price_change_24h: PropTypes.number,
      }).isRequired,
      volume24h: PropTypes.number,
    })
  ).isRequired,
  getLogoURI: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

export default React.memo(TokenBubbles);
