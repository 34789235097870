// HomePage.jsx

import React, { useState } from 'react';
import { FaDiscord, FaWallet, FaEnvelope } from 'react-icons/fa';
import { useWallet, useConnect, supportedConnectors } from '@alephium/web3-react';
import { useAuth } from './auth';
import TokenTicker from './tokens/tokenticker';
import { useWalletModal } from './walletmodalcontext';
import './homepage.css';

const HomePage = () => {
  const walletContext = useWallet();
  const connectionStatus = walletContext?.connectionStatus;
  const wallet = walletContext?.account;
  const connected = connectionStatus === 'connected';
  const walletAddress = wallet?.address || '';

  const { hasSignedUp, isApproved, checkingAuth } = useAuth();

  const [showSignup, setShowSignup] = useState(false);
  const [formData, setFormData] = useState({
    discordName: '',
    walletName: walletAddress,
    email: '',
    telegramUsername: '',
    comments: '',
    agreement: false,
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleOpenModal } = useWalletModal(); // Access the modal context

  // No need to define handleWalletConnect here since it's centralized

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!formData.agreement) {
      alert('You must agree to the User Agreement to participate.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('https://dohcloud.azurewebsites.net/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert('There was an issue submitting your data. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="home-container">
      <TokenTicker />

      <div className="home-box">
        <h1>Decentralized. Open. Hub.</h1>
        <p className="subtitle">
          Be a part of the future by joining our exclusive beta phase.
          Want to learn more?{' '}
          <a href="https://docs.doh.money" target="_blank" rel="noopener noreferrer">
            docs.doh.money
          </a>.
        </p>

        {!connected ? (
          <div className="connect-wallet-container">
            {/* On Click, Open the Wallet Modal */}
            <button onClick={handleOpenModal} className="connect-button">
              Connect Wallet
            </button>
          </div>
        ) : checkingAuth ? (
          <p>Checking signup status...</p>
        ) : hasSignedUp ? (
          <div className="already-signed-up-message">
            <p>
              Thank you for signing up. You now have access to Tokens; please{' '}
              {isApproved ? 'proceed to the Node Manager.' : 'wait for approval to access the Node Manager.'}
            </p>
          </div>
        ) : !showSignup && (
          <button className="beta-button" onClick={() => setShowSignup(true)}>
            Join Beta Phase1 Testing
          </button>
        )}

        {showSignup && !submitted && !hasSignedUp && (
          <form className="signup-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <FaDiscord className="icon" />
              <input
                type="text"
                name="discordName"
                value={formData.discordName}
                onChange={handleInputChange}
                placeholder="Discord Name"
                required
              />
            </div>
            <div className="form-group">
              <FaWallet className="icon" />
              <input
                type="text"
                name="walletName"
                value={formData.walletName}
                onChange={handleInputChange}
                placeholder="Wallet Address"
                readOnly={connected}
                required
              />
            </div>
            <div className="form-group">
              <FaEnvelope className="icon" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
              />
            </div>

            <div className="form-group">
              <textarea
                name="comments"
                value={formData.comments}
                onChange={handleInputChange}
                placeholder="Additional Comments (Optional)"
              />
            </div>
            <div className="form-group checkbox-group">
              <input
                type="checkbox"
                name="agreement"
                checked={formData.agreement}
                onChange={handleInputChange}
                required
              />
              <label>
                I agree to the{' '}
                <a href="https://your-user-agreement-link.com" target="_blank" rel="noopener noreferrer">
                  User Agreement
                </a>{' '}
                for participating in the DOH Beta.
              </label>
            </div>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        )}

        {submitted && (
          <div className="success-message">
            <h2>Thank you for signing up!</h2>
            <p>
              You now have access to Tokens. Please wait for approval to access the Node Manager.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
