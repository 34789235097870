import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { ANS } from "@alph-name-service/ans-sdk";
import { addressFromContractId, groupOfAddress, hexToString } from '@alephium/web3';
import './tokens.css'; // Use consolidated CSS
import reverseNameResolverArtifact from '../ans/reverse_name_resolver/ReverseNameResolver.ral.json'; // Ensure the path is correct
import { ReverseNameResolverInstance } from '../ans/ts'; // Ensure the path and export are correct
import PropTypes from 'prop-types';
import { FaWallet } from 'react-icons/fa'; // Import wallet icon from react-icons
import Wallet from './wallet'; // Import the Wallet component

Chart.register(ArcElement, Tooltip, Legend);

// Define REVERSE_NAME_RESOLVERS mapping for second name service
const REVERSE_NAME_RESOLVERS = {
  0: '6c7075ed4c407c4e20ae39341820240a4065fe69c3840960d2ee2633daf8b000',
  1: '40be2751efbf30395c079278972fbe6838f53a6e240f7b30ebfe877b7dddcd01',
  2: 'cff6d6016d3160fd5818d92effa79594a4dceec572895d953f1a76f0163ff902',
  3: '5777c6381f8dd67297793a4eb6d1e8a1f0de545f5fa4e129d25f4f08d382bd03'
};

// Define custom names mapping
const CUSTOM_NAMES = {
  "wwXHuyXVFBv3zxNf7N3g4D1t2HessF1bpNj6ydDQ1r1m": "ALPH/DOH EX LP",
  "27Ub32AhfC9ULKGKGUTdDGU2ehvUN55aLS4oU8nmW3x9M": "ALPH EX LP",
  "25GTexSvCdbTSewcHfKbVmJKRisZ9cEyJQaNwAgEo5RMy": "APAD EX LP",
  "22iqxjtK14yAD12bE4yiGfJL6XEcoKkMmFZ76DyWtg3wM": "AYIN EX LP",
  "285kUM2LojaKLDPmnc8XxT1azu1G9zR6jjmeEkewJaaWK": "USDT/USDC Stable EX LP",
  "zVRuf1edCyysNDy5nSXEdNcrCJCehfMVpD6kcWqDd9fd": "ALPH/USDT EX LP",
  "24LsfSVVpkFHWFDZpRduuro2WDab3XfmjfyYcAg43K2fq": "ALPH/TOP EX LP",
  "wLqG2MwkA6Y3U53v9sn7NgrcuQGvsNeqm6v6v8GxnGSb": "ALPH/ANS EX LP",
   "27oibfZEc2EcdUNxg8L6VshJ3R1WVCRqGAGuFHG6AMhkK": "GRUMPY/ALPH EX LP"
};

const formatBalance = (balance) => {
  if (balance >= 1e9) return (balance / 1e9).toFixed(2) + 'B';  // Billions
  if (balance >= 1e6) return (balance / 1e6).toFixed(2) + 'M';  // Millions
  if (balance >= 1e3) return (balance / 1e3).toFixed(2) + 'K';  // Thousands
  return balance.toFixed(2);  // Less than a thousand
};

const truncateName = (name) => {
  return name.length > 16 ? `${name.slice(0, 8)}...${name.slice(-8)}` : name;
};

const TopHoldersTable = ({ tokenAddress, pairAddress, onHolderSelect }) => {
  const [holders, setHolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedWalletAddress, setSelectedWalletAddress] = useState(pairAddress); // Default to LP (pairAddress)
  const [holderNames, setHolderNames] = useState({});
  const [nameSources, setNameSources] = useState({});
  const [tokenSymbol, setTokenSymbol] = useState(''); // State to store the token symbol
  const [showHolders, setShowHolders] = useState(true); // Toggle for displaying holders section

  useEffect(() => {
    const fetchHolders = async () => {
      if (!tokenAddress) {
        setError('Token address is missing.');
        return;
      }

      setLoading(true);
      setError(null);
      setHolders([]);
      setHolderNames({});
      setNameSources({});

      try {
        const response = await axios.get(
          `https://dohcloud.azurewebsites.net/api_GetTopHolders?tokenAddress=${tokenAddress}`
        );

        if (!response.data.holders || response.data.holders.length === 0) {
          setError('No holders data found.');
          setLoading(false);
          return;
        }

        const tokenDecimals = response.data.holders[0].token.decimals || 18;
        const circulatingSupply = parseFloat(response.data.holders[0].circulatingSupply) / Math.pow(10, tokenDecimals);
        const holdersData = response.data.holders[0].holders || [];

        // Extract token symbol if available
        const symbol = response.data.holders[0].token.symbol || 'TOKEN';
        setTokenSymbol(symbol);

        const sortedHolders = holdersData
          .map(holder => {
            const realBalanceRaw = parseFloat(holder.balance) / Math.pow(10, tokenDecimals);
            const percentageOwnership = ((realBalanceRaw / circulatingSupply) * 100).toFixed(2);

            return {
              ...holder,
              realBalanceRaw,
              realBalance: formatBalance(realBalanceRaw),
              percentage: percentageOwnership,
            };
          })
          .sort((a, b) => b.realBalanceRaw - a.realBalanceRaw); // Ensure numeric sort

        setHolders(sortedHolders);

        // Fetch ANS names for each holder, fallback to second name service if no name found
        sortedHolders.forEach(holder => {
          fetchAnsNameOrSecondService(holder.userAddress);
        });
      } catch (err) {
        console.error(err);
        setError('Failed to fetch holders.');
      } finally {
        setLoading(false);
      }
    };

    fetchHolders();
  }, [tokenAddress]);

  // Fetch ANS name or fallback to second name service for each holder address
  const fetchAnsNameOrSecondService = async (address) => {
    // Handle pairAddress separately
    if (address === pairAddress) {
      const pairName = `${tokenSymbol} AYIN LP`;
      setHolderNames(prevState => ({ ...prevState, [address]: pairName }));
      setNameSources(prevState => ({ ...prevState, [address]: 'pair' }));
      return;
    }

    // Check if the address has a custom name
    if (CUSTOM_NAMES[address]) {
      setHolderNames(prevState => ({ ...prevState, [address]: CUSTOM_NAMES[address] }));
      setNameSources(prevState => ({ ...prevState, [address]: 'custom' }));
      return;
    }

    try {
      const ans = new ANS('mainnet');
      const profile = await ans.getProfile(address);

      if (profile && profile.name) {
        setHolderNames(prevState => ({ ...prevState, [address]: profile.name.toUpperCase() }));
        setNameSources(prevState => ({ ...prevState, [address]: 'ans' }));
      } else {
        const secondNameServiceName = await fetchReverseName(address);
        if (secondNameServiceName) {
          setHolderNames(prevState => ({ ...prevState, [address]: secondNameServiceName }));
          setNameSources(prevState => ({ ...prevState, [address]: 'second' }));
        } else {
          setHolderNames(prevState => ({ ...prevState, [address]: formatWalletAddress(address) }));
          setNameSources(prevState => ({ ...prevState, [address]: 'none' }));
        }
      }
    } catch (error) {
      console.error(error);
      setHolderNames(prevState => ({ ...prevState, [address]: formatWalletAddress(address) }));
      setNameSources(prevState => ({ ...prevState, [address]: 'none' }));
    }
  };

  const fetchReverseName = async (address) => {
    try {
      const group = groupOfAddress(address);
      const reverseNameResolverContractId = REVERSE_NAME_RESOLVERS[group];

      if (!reverseNameResolverContractId) {
        console.warn(`No reverse name resolver contract found for group ${group}`);
        return null;
      }

      const reverseNameResolver = new ReverseNameResolverInstance(
        addressFromContractId(reverseNameResolverContractId),
        reverseNameResolverArtifact.abi
      );

      const reverseNameHex = (await reverseNameResolver.view.getNameByAddress({ args: { address } })).returns;
      return hexToString(reverseNameHex);
    } catch (error) {
      console.error(`Error fetching reverse name for address ${address}:`, error.message || error);
      return null;
    }
  };

  const formatWalletAddress = (address) => `${address.slice(0, 4)}...${address.slice(-4)}`;

  const getNameColor = (address) => {
    switch (nameSources[address]) {
      case 'ans':
        return '#FF00FF'; // Neon Pink for ANS names
      case 'second':
        return '#00FF00'; // Bright Green for Second Name Service names
      case 'custom':
        return '#FFA500'; // Orange for Custom Names
      case 'pair':
        return '#1E90FF'; // Dodger Blue for Pair Address
        case 'none':
          default:
            return '#FFFFFF'; // White for addresses with no name
        }
      };
    
      const pieChartData = useMemo(() => ({
        labels: holders.map(holder => truncateName(holderNames[holder.userAddress] || formatWalletAddress(holder.userAddress))),
        datasets: [
          {
            label: 'Percentage of Total Supply',
            data: holders.map(holder => holder.percentage),
            backgroundColor: holders.map(holder =>
              holder.userAddress === selectedWalletAddress
                ? 'rgba(255, 165, 0, 1)'  // Orange for selected
                : holder.userAddress === pairAddress
                  ? 'rgba(30, 144, 255, 0.8)'  // Dodger Blue for Pair Address
                  : 'rgba(51, 51, 51, 0.8)'  // Dark Gray for others
            ),
            borderColor: 'rgba(0, 0, 0, 0.8)',
            hoverBorderColor: 'rgba(255, 215, 0, 1)',
            borderWidth: 2,
          },
        ],
      }), [holders, holderNames, selectedWalletAddress, pairAddress]);
    
      const pieChartOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allow for custom height
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const holder = holders[tooltipItem.dataIndex];
                return `${tooltipItem.label}: ${tooltipItem.raw}% (${holder.realBalance})`;
              },
            },
          },
          legend: {
            display: false // Hide legend for a cleaner look
          },
        },
        layout: {
          backgroundColor: 'transparent', // Ensure the layout background is transparent
        },
        elements: {
          arc: {
            backgroundColor: 'transparent', // Ensure arcs don't have their own background
          },
        },
        onHover: (event, elements) => {
          if (elements.length > 0) {
            event.native.target.style.cursor = 'pointer';
          } else {
            event.native.target.style.cursor = 'default';
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const index = elements[0].index;
            const holderAddress = holders[index].userAddress;
            setSelectedWalletAddress(holderAddress); // Update the selected wallet on pie chart click
            onHolderSelect(holderAddress); // Pass selected address to parent
            window.open(`https://explorer.alephium.org/addresses/${holderAddress}`, '_blank');
          }
        },
      };
    
      const handleWalletAddressClick = (address) => {
        setSelectedWalletAddress(address); // Set the selected wallet address
        onHolderSelect(address); // Pass selected address to parent
      };
    
      const getAddressType = (holderAddress) => {
        if (holderAddress === tokenAddress) {
          return ' (Contract)';
        } else if (holderAddress === pairAddress) {
          return ' (LP)';
        }
        return ''; // No label if it’s neither a contract nor LP address
      };
    
      return (
        <div className="top-holders-container">
          <div className={`holders-info-section ${showHolders ? 'holders-info-visible' : 'holders-info-hidden'}`}>
            {/* Left side: Token Holders Table */}
            <div className="top-holders-table-container">
              {loading && <p className="loading-message">Loading top holders...</p>}
              {error && <p className="error-message">{error}</p>}
    
              <table className="shared-table holders-table">
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>%</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {holders.map((holder) => {
                    const isSelected = selectedWalletAddress === holder.userAddress;
                    return (
                      <tr
                        key={holder.userAddress} // Unique wallet address as key
                        onClick={() => handleWalletAddressClick(holder.userAddress)}
                        className={isSelected ? 'selected' : ''}
                        style={{ backgroundColor: isSelected ? 'var(--hover-color)' : 'inherit' }} // Inline style for immediate color change
                      >
                        <td>
                          <a
                            href={`https://explorer.alephium.org/addresses/${holder.userAddress}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()} // Prevent link click from affecting the row selection
                            className="wallet-icon-link"
                            title="View on Explorer"
                          >
                            <FaWallet className="wallet-icon-small" />
                          </a>
                          <span style={{ color: getNameColor(holder.userAddress), marginLeft: '8px' }}>
                            {truncateName(holderNames[holder.userAddress] || formatWalletAddress(holder.userAddress))}
                          </span>
                          {getAddressType(holder.userAddress)}
                        </td>
                        <td>
                          <div className="percentage-container">
                            {holder.percentage} %
                            <div
                              className="percentage-bar"
                              style={{ width: `${holder.percentage}%` }}
                              title={`${holder.percentage}% held by this address`}
                            ></div>
                          </div>
                        </td>
                        <td>{holder.realBalance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
    
            {/* Right side: Pie Chart */}
            <div className="holders-chart-container">
              <Pie data={pieChartData} options={pieChartOptions} />
            </div>
          </div>
        </div>
      );
    };
    
    TopHoldersTable.propTypes = {
      tokenAddress: PropTypes.string.isRequired,
      pairAddress: PropTypes.string.isRequired,
      onHolderSelect: PropTypes.func.isRequired,
    };
    
    export default TopHoldersTable;
    