import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaCoins, FaServer, FaTasks } from 'react-icons/fa'; // Use FaTasks for MintUploader icon
import './sidebar.css';

const Sidebar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    if (isMobile) {
      setIsExpanded((prev) => !prev);
    }
  };

  return (
    <div
      id="sidebar"
      className={`sidebar ${isExpanded ? 'expanded' : ''}`}
      onClick={isMobile ? toggleSidebar : null}
      onMouseEnter={!isMobile ? () => setIsExpanded(true) : null}
      onMouseLeave={!isMobile ? () => setIsExpanded(false) : null}
    >
      <div className="nav-links">
        <Link to="/home" className="link">
          <FaHome className="icon" />
          <span className="link-text">Home</span>
        </Link>
        <Link to="/nodes" className="link">
          <FaServer className="icon" />
          <span className="link-text">Nodes</span>
        </Link>
        <Link to="/tokens" className="link">
          <FaCoins className="icon" />
          <span className="link-text">Tokens</span>
        </Link>

      </div>
    </div>
  );
};

export default Sidebar;
