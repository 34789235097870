// Navbar.js
import React, { useState, useEffect } from 'react';
import { useWallet } from '@alephium/web3-react';
import { useWalletModal } from './walletmodalcontext';
import useWalletConnection from './usewalletconnection';
import dohLogo from './assets/logos/DOH_Official.png';
import dohBetaLogo from './assets/logos/dohbeta.png'; // Import the beta logo
import { ANS } from "@alph-name-service/ans-sdk";
import './navbar.css';

const Navbar = ({ onSearch }) => {
  const { handleOpenModal } = useWalletModal();
  const walletContext = useWallet();
  const { connectionStatus, account } = walletContext || {};
  const connected = connectionStatus === 'connected';
  const walletAddress = account?.address || '';

  const { handleDisconnect } = useWalletConnection();
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [ansName, setAnsName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (connected && walletAddress) {
      fetchAnsProfile(walletAddress);
    }
  }, [connected, walletAddress]);

  const fetchAnsProfile = async (address) => {
    try {
      const ans = new ANS('mainnet');
      const profile = await ans.getProfile(address);
      setAnsName(profile?.name || '');
    } catch (error) {
      console.error('Error fetching ANS profile:', error);
    }
  };

  const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  const onDisconnect = async () => {
    setIsDisconnecting(true);
    try {
      await handleDisconnect();
      walletContext.connectionStatus = 'disconnected';
      setIsDisconnecting(false);
    } catch (error) {
      console.error('Failed during disconnection:', error);
      setIsDisconnecting(false);
    }
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    onSearch(query); // Pass search query to the parent component
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <img src={dohLogo} alt="DOH Logo" className="logo" />
        <img src={dohBetaLogo} alt="DOH Beta Logo" className="logo-beta" /> {/* New Image */}
      </div>

      {/* Search bar centered in the navbar */}
      <div className="centered-search-bar">
        <input
          type="text"
          placeholder="Search tokens..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-input"
        />
      </div>

      <div className="wallet-connect">
        {!connected ? (
          <button onClick={handleOpenModal} className="connect-button">
            Connect Wallet
          </button>
        ) : (
          <div className="wallet-info">
            <span className="ans-name">{ansName || truncateAddress(walletAddress)}</span>
            <button
              onClick={onDisconnect}
              className="disconnect-button"
              disabled={isDisconnecting}
            >
              {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
