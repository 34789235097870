import React, { useEffect, useState } from 'react';

function DOHmail() {
  const [account, setAccount] = useState(null);
  const [emails, setEmails] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Check if user is authenticated (if you have a way to track this)
    const storedAccount = localStorage.getItem('account'); // Example: Store account info in localStorage
    if (storedAccount) {
      setAccount(JSON.parse(storedAccount));
      setInitialized(true);
    } else {
      setInitialized(true);
    }
  }, []);

  const handleLogin = async () => {
    try {
      const response = await fetch('https://dohcloud.azurewebsites.net/api/dohmail', {
        method: 'POST', // Use POST method
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Save the access token for future requests
      const accessToken = data.access_token;
      if (accessToken) {
        const emailResponse = await fetch("https://graph.microsoft.com/v1.0/me/messages", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!emailResponse.ok) {
          throw new Error(`Failed to fetch emails: ${emailResponse.status}`);
        }

        const emailData = await emailResponse.json();
        setEmails(emailData.value);
        setAccount({ accessToken }); // Save access token or any account info
        localStorage.setItem('account', JSON.stringify({ accessToken })); // Store in localStorage
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed: " + error.message); // Notify user of the error
    }
  };

  const handleLogout = () => {
    setAccount(null);
    setEmails([]);
    localStorage.removeItem('account'); // Clear account info
  };

  return (
    <div>
      <h1>DOHmail</h1>
      {account ? (
        <div>
          <p>Welcome!</p>
          <button onClick={handleLogout}>Logout</button>
          <h2>Your Emails</h2>
          <ul>
            {emails.length > 0 ? (
              emails.map(email => (
                <li key={email.id}>{email.subject}</li>
              ))
            ) : (
              <li>No emails found.</li>
            )}
          </ul>
        </div>
      ) : (
        <button onClick={handleLogin}>Login</button>
      )}
    </div>
  );
}

export default DOHmail;
