import React from 'react';
import { FaTrash } from 'react-icons/fa';
import NodeConsole from './nodeconsole'; // Import NodeConsole if needed
import './nodedetails.css'; // Include any necessary styling.
const NodeDetails = ({ selectedNode, deleteNode, metrics }) => {
  return (
    <div className="node-detail-view">
      <h3>Node Details: {selectedNode.nodeName}</h3>
      <p>
        <strong>IP:</strong> {selectedNode.publicIP || 'Pending'}
      </p>
      <p>
        <strong>Status:</strong> {selectedNode.status}
      </p>

      {selectedNode.publicIP && (
        <div className="api-link">
          <a
            href={`http://${selectedNode.publicIP}:12973/docs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            API Swagger
          </a>
        </div>
      )}

      {/* Display the metrics here */}
      <div className="metrics">
        <h4>Node Metrics</h4>
        <p>
          <strong>CPU Usage:</strong> {metrics.cpuUsage || 'N/A'}
        </p>
        <p>
          <strong>Memory Usage:</strong> {metrics.memoryUsage || 'N/A'}
        </p>
        {/* Add more metrics as needed */}
      </div>

      <NodeConsole nodeName={selectedNode.nodeName} /> {/* Optional if logs are needed */}

      <div className="button-container">
        <button onClick={() => deleteNode(selectedNode.nodeName)} className="delete-button">
          <FaTrash /> Delete Node
        </button>
      </div>
    </div>
  );
};

export default NodeDetails;
