// Updated useWalletConnection.js
import { useConnect, useAlephiumConnectContext, supportedConnectors } from '@alephium/web3-react';

const useWalletConnection = () => {
  const { connect } = useConnect();
  const { disconnect } = useAlephiumConnectContext(); // Use the correct context hook

  const handleWalletConnect = async (walletType) => {
    try {
      let connector;

      switch (walletType) {
        case 'injected':
          connector = supportedConnectors.extension;
          console.log('Attempting to connect using Alephium Extension...');
          break;
        case 'desktopWallet':
          connector = supportedConnectors.desktop;
          console.log('Attempting to connect using Alephium Desktop Wallet...');
          break;
        case 'walletConnect':
          connector = supportedConnectors.walletConnect;
          console.log('Attempting to connect using WalletConnect...');
          break;
        default:
          throw new Error('Unknown wallet type');
      }

      await connect(connector);
      console.log(`${walletType} connected successfully`);
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      alert('Failed to connect wallet. Please try again.');
    }
  };

  const handleDisconnect = async () => {
    try {
      console.log('Attempting to disconnect...');
      if (typeof disconnect === 'function') {
        await disconnect();
        console.log('Wallet disconnected successfully');
      } else {
        console.warn('Disconnect function is not available.');
      }
    } catch (error) {
      console.error('Failed to disconnect wallet:', error);
      alert('Failed to disconnect wallet. Please try again.');
    }
  };

  return { handleWalletConnect, handleDisconnect };
};

export default useWalletConnection;