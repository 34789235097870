import React from 'react';
import './toolbar.css';

const Filter = ({ filters, setFilters }) => {
  const handleFilterChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  return (
    <div className="toolbar-container">
      {/* Remove or comment this out if the feedback button is rendered elsewhere */}
      {/* <button className="feedback-button"> Feedback </button> */}

      {/* Right-aligned filter selections */}
      <div className="unique-filter-dropdown">
        <div className="unique-filter-dropdown-item">
          <label>
            <input
              type="checkbox"
              checked={filters.hideZeroVolume}
              onChange={() => handleFilterChange('hideZeroVolume')}
            />
            Hide Zero Volume
          </label>
        </div>
        <div className="unique-filter-dropdown-item">
          <label>
            <input
              type="checkbox"
              checked={filters.hideSub500Liquidity}
              onChange={() => handleFilterChange('hideSub500Liquidity')}
            />
            Hide Liquidity &lt; 500
          </label>
        </div>
        <div className="unique-filter-dropdown-item">
          <label>
            <input
              type="checkbox"
              checked={filters.hideNonAlphTokens}
              onChange={() => handleFilterChange('hideNonAlphTokens')}
            />
            Hide Non-ALPH Tokens
          </label>
        </div>
      </div>
    </div>
  );
};

export default Filter;
